import React, {useEffect, useState} from "react";
import MosqueDetails from "./MosqueDetails";
import MemberDetails from "./MemberDetails";
import queryString from "../../../../../util/queryString";
import {NEW_ONBOARD_MEMBER_SUCCESS, NEW_ONBOARD_SUCCESS} from "../../../customerActionType";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {
    setIsOnboarded,
    setIsOnboardedMember,
    setInitialOnboarded,
    setInitialMemberOnboarded
} from "../../../customerAction";
import { useTranslation } from "react-i18next";
const SCREEN_ONBOARD_MEMBER = 'onboard-member';
const SCREEN_ONBOARD_MOSQUE = 'onboard-mosque';

function OnboardingTab() {
    const [selectedSegment, setselectedSegment] = useState(SCREEN_ONBOARD_MOSQUE);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {t} = useTranslation()

    const [mosque_payment_success, setMosquePaymentSuccess] = useState(0);
    const [member_payment_success, setMemberPaymentSuccess] = useState(0);

    useEffect(() => {
        let payment_method = searchParams.get("payment");
        let onboarding_mode = searchParams.get("onboarding");
        let msid = searchParams.get("msid");
        let eid = searchParams.get("eid");
        if (payment_method && onboarding_mode === "member" && msid) {
            setMemberPaymentSuccess(2)
            setselectedSegment(SCREEN_ONBOARD_MEMBER);
            dispatch({
                type: NEW_ONBOARD_MEMBER_SUCCESS,
                payload: {},
                is_onboarding_member: false,
                is_onboarded_member: true
            });
        }
        if (payment_method && onboarding_mode === "mosque" && eid) {
            setMosquePaymentSuccess(2)
            setselectedSegment(SCREEN_ONBOARD_MOSQUE);
            dispatch({
                type: NEW_ONBOARD_SUCCESS,
                payload: {},
                is_onboarding: false,
                is_onboarded: true
            });
        }
    }, [searchParams])

    const setSegment = (selectedSegment) => {
        dispatch(setIsOnboarded(false));
        dispatch(setIsOnboardedMember(false));
        setMemberPaymentSuccess(0);
        setMosquePaymentSuccess(0);
        dispatch(setInitialOnboarded());
        dispatch(setInitialMemberOnboarded());
        setselectedSegment(selectedSegment);
    };

    const queryObj = queryString.parse(window.location.search);

    useEffect(() => {
        if (queryObj?.asMember === "true") {
            setselectedSegment(SCREEN_ONBOARD_MEMBER);
        }
    }, [queryObj]);

    return (
        <div>
            <div>
                <ul className="d-flex information-modal">
                    <li
                        className="information col-12"
                        style={{
                            cursor: "pointer",
                            fontWeight: 600,
                            backgroundColor:
                                selectedSegment === SCREEN_ONBOARD_MOSQUE ? "#D0004B" : "#fff",
                            color: selectedSegment === SCREEN_ONBOARD_MOSQUE ? "#fff" : "#D0004B",
                            borderRadius:
                                selectedSegment === SCREEN_ONBOARD_MOSQUE ? "12px 12px 0px 0px" : "",
                        }}
                        onClick={() => setSegment(SCREEN_ONBOARD_MOSQUE)}
                    >
                        {t('Register Your Mosque')}{" "}
                    </li>
                </ul>
            </div>
            <div className="row board-box">
                {selectedSegment === SCREEN_ONBOARD_MOSQUE ? (
                    <MosqueDetails
                        defaultStep={mosque_payment_success}
                        setSegment={setSegment}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default OnboardingTab;
