import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import translations from "../translations";

const DonationFormBanner = ({language}) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };
  const {t} = useTranslation()
  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im"  style={{background: "white"}}  onClick={() => handleRedirect()}>
          <img style={{ height: "70px", width: "auto" }} src="/assets/mosqapp-logo-new.svg"/>
        </div>
        <br/>
        <div className="login_content" style={{marginTop : "-45px", fontSize:'32px'}}>
          <h3>{translations[language?.toUpperCase()]['HELP YOUR MOSQUE']}</h3>
          <p style={{width:'300px', margin:'0 auto'}}>
          {t('Either late when Stenorat listens in. Especially this time. Chaotic/damaged label, super-magnificent and gigantic. ')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DonationFormBanner;
