import {combineReducers} from "redux";

import appReducer from './modules/appReducer'
import customerReducer from './modules/Customer/customerReducer'
import adminReducer from './modules/Admin/adminReducer'
import mosqueReducer from './modules/Mosque/mosqueReducer'
import uploadReducer from './modules/Mosque/uploadProgressReducer'

export default combineReducers({
    appReducer,
    customerReducer,
    adminReducer,
    mosqueReducer,
    uploadReducer
});
