import React, { useEffect, useState } from "react";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import CurveLineChart from "../../../../../components/D3Charts/CurveLineChart";
import Icon from "../../../../../components/Icon";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const RevenueGraphModal = ({ onHide }) => {
  const [details, setDetails] = useState({});
  const {t} = useTranslation()
  const entityFinRevenue = useSelector(state => state.adminReducer.entityFinRevenue);

  useEffect(() => {
    if (entityFinRevenue?.length > 0) {
      const result = {};
      entityFinRevenue.forEach(data => {
        const { year, month } = data;
        const { amount } = data
        if (!result[year]) {
          result[year] = Array(12).fill(0); // Initialize the array with zeros for all months
          setDetails(result);
        }
        result[year][month - 1] = amount; // Update the amount for the corresponding month
        setDetails(result);
      });
    }
  }, [entityFinRevenue]);


  const transformedResult = Object.entries(details).map(([year, data]) => ({
    name: year.toString(),
    data: data
  }));

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" onClick={onHide}>
        {/* <Icon iconSize="2xs" iconName="close" onClick={onHide}/> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path d="M17 2.83203C14.1981 2.83203 11.4592 3.66289 9.12947 5.21955C6.79977 6.7762 4.984 8.98873 3.91175 11.5773C2.83951 14.166 2.55896 17.0144 3.10559 19.7625C3.65221 22.5105 5.00146 25.0348 6.9827 27.016C8.96395 28.9973 11.4882 30.3465 14.2363 30.8932C16.9843 31.4398 19.8328 31.1592 22.4214 30.087C25.01 29.0147 27.2225 27.199 28.7792 24.8693C30.3359 22.5396 31.1667 19.8006 31.1667 16.9987C31.1667 15.1383 30.8003 13.2961 30.0883 11.5773C29.3764 9.85857 28.3329 8.29685 27.0174 6.98135C25.7019 5.66586 24.1402 4.62235 22.4214 3.9104C20.7026 3.19846 18.8604 2.83203 17 2.83203ZM21.5475 19.5345C21.6803 19.6662 21.7857 19.8229 21.8576 19.9955C21.9296 20.1682 21.9666 20.3533 21.9666 20.5404C21.9666 20.7274 21.9296 20.9125 21.8576 21.0852C21.7857 21.2578 21.6803 21.4145 21.5475 21.5462C21.4159 21.679 21.2592 21.7844 21.0865 21.8563C20.9139 21.9282 20.7287 21.9652 20.5417 21.9652C20.3547 21.9652 20.1695 21.9282 19.9969 21.8563C19.8243 21.7844 19.6676 21.679 19.5359 21.5462L17 18.9962L14.4642 21.5462C14.3325 21.679 14.1758 21.7844 14.0032 21.8563C13.8306 21.9282 13.6454 21.9652 13.4584 21.9652C13.2714 21.9652 13.0862 21.9282 12.9136 21.8563C12.7409 21.7844 12.5842 21.679 12.4525 21.5462C12.3198 21.4145 12.2144 21.2578 12.1425 21.0852C12.0705 20.9125 12.0335 20.7274 12.0335 20.5404C12.0335 20.3533 12.0705 20.1682 12.1425 19.9955C12.2144 19.8229 12.3198 19.6662 12.4525 19.5345L15.0025 16.9987L12.4525 14.4629C12.1858 14.1961 12.0359 13.8343 12.0359 13.457C12.0359 13.0798 12.1858 12.718 12.4525 12.4512C12.7193 12.1844 13.0811 12.0346 13.4584 12.0346C13.8356 12.0346 14.1975 12.1844 14.4642 12.4512L17 15.0012L19.5359 12.4512C19.8026 12.1844 20.1645 12.0346 20.5417 12.0346C20.919 12.0346 21.2808 12.1844 21.5475 12.4512C21.8143 12.718 21.9642 13.0798 21.9642 13.457C21.9642 13.8343 21.8143 14.1961 21.5475 14.4629L18.9975 16.9987L21.5475 19.5345Z" fill="#1A1A1A"/>
        </svg>
      </Box>
      <Label fontSize="24px" fontWeight="700" justifyContent="center">
      {t('Total Income')}
      </Label>
      <CurveLineChart
        dataSet={transformedResult}
        colors={["#80CBC4", "#D0004B"]}
        categories={[
          "Jan",
          "Feb",
          "Mrt",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec",
        ]}
      />
     </Box>
  );
};

export default RevenueGraphModal;
