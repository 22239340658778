import React from "react";
import Icon from "../../../../../components/Icon";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import { useTranslation } from "react-i18next";

const DisableMemberModal = ({
  show,
  handleDelete,
  setshow,
  text,
  isProfileModal = false,
	setComment,
	comment
}) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    setshow(!show);
    handleDelete();
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div onClick={() => setshow(null, false)}>
          <Icon
            // style={{zIndex: 2000}}
            iconName="blackCross"
          />
        </div>
      </div>
      <div className="congrats-screen">
        <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
        <Box mt="15px">
          <h4>{text}</h4>
          {isProfileModal && (
            <div>
							<Label>{t('Mention Reason')}<span style={{ color: "#FE1717" }}>*</span></Label>
              <textarea
                className="input-boxx"
                style={{ padding: "10px" }}
                maxLength={150}
								onChange={(e)=>setComment(e.target.value)}
              ></textarea>
            </div>
          )}
        </Box>
        {/* <Label color="#7E7E7E">{text}</Label> */}

        <div className="d-flex justify-content-around mt-3">
          <button className="prev-bt" onClick={() => setshow(!show)}>
            {t("Cancel")}
          </button>
          <button className={comment.length>0?"fill-bt":"gray-bt"} disabled={comment.length===0} onClick={() => handleOnClick()}>
            {t("Yes")}
          </button>
        </div>
      </div>
    </>
  );
};

export default DisableMemberModal;
