import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons/Button";
import Label from "../../../../../components/Label";
import { Select, DatePicker } from "antd";
import { showNotification } from "../../../../../util/Utility";
import styled from "styled-components";
import { callApiV2 } from "../../../../../util/apiCaller";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon/Icon";
import dayjs from "dayjs";
import { UPDATE_APPROVAL_STATUS } from "../../../mosqueActionType";
import {
  fetchCities,
  fetchCountries,
  memberApprove,
  memberDecline,
} from "../../../mosqueAction";
import {
  fetchCustomerInfoList,
  sendMemberReminders,
  setCustomerUpdateStatus,
  updateCustomerInfo,
  updateMemberSubscriptionInfo,
  setMemberDetailsVisible,
} from "../../../mosqueAction";
import Dropdown from "../../../../../components/Dropdown";
import { getPaymentMethodOption } from "../../../../../util/Utility";
import { SET_MEMBER_DETAILS_VISIBLE } from "../../../mosqueActionType";
import { useParams } from "react-router-dom";
import { getFormattedTime } from "../../../../../util/timeUtility";
import { useHistory } from "react-router-dom";
import { escape } from "lodash";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const paymentMethodOption = [
  {
    lable: "Automatic",
    value: "auto",
  },
  {
    label: "Manual Pay",
    value: "manual",
  },
];

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;
const options = getPaymentMethodOption();

const EditForm = ({ onHide }) => {
  const dispatch = useDispatch();
  const { memberId } = useParams();
  const [isNext, setIsNext] = useState(false);
  const [isAddedNew, setIsAddedNew] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isDecline, setIsDecline] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [activeMemberDetails, setActiveMemberDetails] = useState(null);
  const [isFreeSubscription, setIsFreeSubscription] = useState(false);
  const [isManualPay, setIsManualPay] = useState(false);
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const activeMember = useSelector((state) => state.mosqueReducer.activeMember);
  const customer = useSelector(
    (state) => state.mosqueReducer.memberDetails?.users
  );
  const isUpdatedSuccessfully = useSelector(
    (state) => state.mosqueReducer.memberDetails?.isUpdatedSuccessfully
  );
  const isApprovalDecline = useSelector(
    (state) => state.mosqueReducer.isApprovalDecline
  );
  const isApprovedApproval = useSelector(
    (state) => state.mosqueReducer.isApprovedApproval
  );
  const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
  const plan_list = memberPlans?.planList;
  const customerList = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoList
  );
  const [errors, setErrors] = useState({});
  const isFetchingCities = useSelector(
    (state) => state.mosqueReducer.isFetchingCities
  );
  useEffect(() => {
    // Filter the customerList based on memberId
    const activeMember = customerList.find((member) => member._id === memberId);
    setActiveMemberDetails(activeMember);
  }, [customerList, memberId]);
 const {t} = useTranslation()
  //console.log("this members details :", customerList);

  useEffect(() => {
    if (activeEntity?._id) {
      dispatch(
        fetchCustomerInfoList({
          entity_id: activeEntity?._id,
          member_id: activeMember?._id,
          pageSize: 30,
          pageNum: 1,
        })
      );
    }
  }, [dispatch, activeEntity]);

  useEffect(() => {
    setIsManualPay(activeMemberDetails?.paymentMode === "manual");
  }, [activeMemberDetails?.paymentMode]);

  useEffect(() => {
    if (activeMemberDetails) {
      handleEmailAndPhoneValidation();
    }
  }, [activeMemberDetails]);
  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const optionsPlan = () => {
    let listValues = [];
    if (plan_list?.length) {
      plan_list.forEach((plan) => {
        if (plan.isFree) {
          // Check for a 'isFree' property in the plan object
          // Don't push the free plan to listValues
          return;
        }
        listValues.push({
          ...plan,
          label: `${plan.plan_name} - € ${plan.amount}`,
          value: plan._id,
        });
      });
    }
    listValues.unshift({
      label: "Gratis abonnement",
      value: "free", //
    });
    return listValues;
  };

  const handleUpdateDetails = (keyName, keyValue) => {
    setErrors({});
    if (keyName === "contact_name") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        name: keyValue,
      }));
    } else if (keyName === "contact_phone") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        phoneNumber: keyValue,
      }));
    } else if (keyName === "country_code") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        countryCode: keyValue,
      }));
    } else if (keyName === "contact_email") {
      keyValue = keyValue.toLowerCase();
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        emailAddress: keyValue,
      }));
    } else if (keyName === "contact_country") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        country: keyValue,
      }));
    } else if (keyName === "contact_city") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        city: keyValue,
      }));
    } else if (keyName === "contact_address") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        address: keyValue,
      }));
    } else if (keyName === "contact_pincode") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        pincode: keyValue,
      }));
    } else if (keyName === "subscription_plan_id") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        subscriptions: [
          {
            ...prevDetails.subscriptions[0],
            subscriptionId: keyValue,
          },
        ],
      }));
    } else if (keyName === "customer_acc_name") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        customerAccountName: keyValue,
      }));
    } else if (keyName === "customer_iban") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        customerIBAN: keyValue,
      }));
    } else if (keyName === "paymentMode") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        paymentMode: keyValue,
      }));
    }
    if (keyName === "subscription_plan_id" && keyValue === "free") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        customerAccountName: "",
        customerIBAN: "",
        paymentMode: "",
      }));
    }
    // Clear customer name and IBAN fields when selecting "manual" payment mode
    else if (keyName === "paymentMode" && keyValue === "manual") {
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        customerAccountName: "",
        customerIBAN: "",
      }));
    } else {
      // Otherwise, update the field normally
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        [keyName]: keyValue,
      }));
    }
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!activeMemberDetails?.name) {
      isValid = false;
      error["contact_name"] = "Dit veld is verplicht";
    }
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(activeMemberDetails?.emailAddress)) {
      isValid = false;
      error["contact_email"] = "Voer alstublieft een geldig e-mailadres in";
    }
    const netherlandsCountryCode = "+31";
    if (activeMemberDetails?.countryCode === netherlandsCountryCode) {
      const phoneNumberRegex = /^\d{9}$/; // 9 digits regex
      if (
        !phoneNumberRegex.test(
          activeMemberDetails?.phoneNumber.replace(/[-()\s]/g, "")
        )
      ) {
        isValid = false;
        error["contact_phone"] =
          "Voer alstublieft een geldig telefoonnummer van 9 cijfers in";
      }
    }
    if (!activeMemberDetails?.country) {
      isValid = false;
      error["contact_country"] = "Dit veld is verplicht";
    }
    if (!activeMemberDetails?.city) {
      isValid = false;
      error["contact_city"] = "Dit veld is verplicht";
    }
    if (
      !(
        activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null ||
        activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== "free"
      ) &&
      !activeMemberDetails?.paymentMode
    ) {
      isValid = false;
      error["paymentMode"] =
        "Selecteer alstublieft de betalingswijze als u een lidmaatschap heeft geselecteerd";
    }
    if (
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null &&
      activeMemberDetails?.customerAccountName &&
      activeMemberDetails?.customerIBAN &&
      !activeMemberDetails?.paymentMode
    ) {
      isValid = false;
      error["paymentMode"] =
        "Please select payment method as automatic";
    }
    if (
      activeMemberDetails?.customerAccountName &&
      !activeMemberDetails?.subscriptions?.[0]?.subscriptionId &&
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null
    ) {
      isValid = false;
      error["customer_acc_name"] = "Please select a membership";
    }
    if (
      activeMemberDetails?.customerIBAN &&
      !activeMemberDetails?.subscriptions?.[0]?.subscriptionId &&
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null
    ) {
      isValid = false;
      error["customer_iban"] = "Please select a membership";
    }
    if (
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null &&
      activeMemberDetails?.paymentMode === "automatic" &&
      !activeMemberDetails?.customerAccountName
    ) {
      isValid = false;
      error["customer_acc_name"] =
        "Please enter the account holder's name";
    }
    if (
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null &&
      activeMemberDetails?.paymentMode === "automatic" &&
      !activeMemberDetails?.customerIBAN
    ) {
      isValid = false;
      error["customer_iban"] = "Please enter the user's IBAN";
    }
    if (
      activeMemberDetails?.customerAccountName &&
      activeMemberDetails?.customerIBAN &&
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null
    ) {
      isValid = false;
      error["subscription_plan_id"] = "Please select a membership";
    }
    if (
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null &&
      activeMemberDetails?.paymentMode === "automatic" &&
      activeMemberDetails?.customerIBAN === "" &&
      activeMemberDetails?.customerAccountName === ""
    ) {
      isValid = false;
      error["subscription_plan_id"] =
        "Please enter the user's IBAN, account holder's name";
    }
    return {
      isValid,
      error,
    };
  };

  const handleEmailAndPhoneValidation = () => {
    let validate = handleValidation();
    if (!validate.isValid) {
      setErrors(validate.error);
      setIsNext(false);
    } else if (
      !!activeMemberDetails?.name &&
      !!activeMemberDetails?.emailAddress &&
      !!activeMemberDetails?.phoneNumber &&
      !!activeMemberDetails?.country &&
      !!activeMemberDetails?.city &&
      !!activeMemberDetails?.subscriptions?.[0]?.subscriptionId &&
      activeMemberDetails?.subscriptions?.[0]?.subscriptionId !== null
        ? !!activeMemberDetails?.paymentMode
        : activeMemberDetails?.paymentMode
    ) {
      setIsNext(true);
    }
  };

  const handleSubmit = () => {
    setErrors({});
    if (activeMemberDetails) {
      if (activeMemberDetails.subscriptions[0].subscriptionId === "free") {
        activeMemberDetails.subscriptions[0].subscriptionId = null;
      }
      if (!activeMemberDetails.paymentMode) {
        activeMemberDetails.paymentMode = null;
      }
      if (!activeMemberDetails.customerAccountName) {
        activeMemberDetails.customerAccountName = null;
      }
      if (!activeMemberDetails.customerIBAN) {
        activeMemberDetails.customerIBAN = null;
      }
      dispatch(
        updateMemberSubscriptionInfo(
          activeMemberDetails?._id,
          activeMemberDetails,
          false
        )
      )
        .then(() => {
          // Redirect after successful submission
          window.location.href = `/mosque/${activeEntity?._id}/info`;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error submitting form:", error);
        });
    }
  };

  console.log("edit member details : ", activeMemberDetails);
  const formattedMembershipDate =
    activeMemberDetails?.subscriptions &&
    activeMemberDetails.subscriptions.length > 0
      ? getFormattedTime(
          activeMemberDetails.subscriptions[0].date,
          "DD MMM YYYY, hh:mm a"
        )
      : "-";

  const handleRedirection = () => {
    window.location.href = `/mosque/${activeEntity?._id}/info`;
  };

  const getRenderButton = () => {
    return (
      <Box display="flex" justifyContent="center">
        <Box>
          <Button
            type={!isNext ? "disabled" : "primary"}
            text={t("Save Details")}
            borderRadius="66px"
            width="15%"
            mt="4"
            mx="auto"
            height="60px"
            onClick={() => handleSubmit()}
          />
        </Box>
        <Box ml="15px">
          <Button
            type={"primary"}
            text={t("Cancel")}
            borderRadius="66px"
            width="15%"
            mt="4"
            mx="auto"
            height="60px"
            onClick={() => handleRedirection()}
          />
        </Box>
      </Box>
    );
  };

  return (
    <div className="add_form form_width">
      <div className="mosque-form">
        <h2>{t('Edit User')}</h2>
        <div className="row">
          <div className="col-lg-6 input-width">
            <label mb="8px">
              {t('Full Name')} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={activeMemberDetails?.name}
              onChange={(e) => {
                handleUpdateDetails("contact_name", e.target.value);
              }}
              type="text"
              placeholder={t("Enter Name")}
              className="input-box"
            />
            {errors.contact_name && (
              <ErrorMessage>{t(errors.contact_name)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 input-width">
            <label mb="8px">
              {t('Email Address')} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={activeMemberDetails?.emailAddress}
              onChange={(e) =>
                handleUpdateDetails("contact_email", e.target.value)
              }
              type="text"
              placeholder={t("Enter Your Email Address")}
              className="input-box"
            />
            {errors.contact_email && (
              <ErrorMessage>{t(errors.contact_email)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">
              {t('Contactnummer')} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={activeMemberDetails?.phoneNumber}
              countryCodes={true}
              countryCodeValue={activeMemberDetails?.country_code}
              setCountryCode={(e) => handleUpdateDetails("country_code", e)}
              onChange={(e) =>
                handleUpdateDetails("contact_phone", e.target.value)
              }
              border={
                activeMemberDetails?.phoneNumber?.length
                  ? "1px solid #606734"
                  : null
              }
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
            {errors.contact_phone && (
              <ErrorMessage>{t(errors.contact_phone)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">{t('Adres gebruike')}r</label>
            <input
              value={activeMemberDetails?.address}
              onChange={(e) =>
                handleUpdateDetails("contact_address", e.target.value)
              }
              type="text"
              placeholder={t("Enter User's Address")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">{t('Postcode')}</label>
            <input
              value={activeMemberDetails?.pincode}
              onChange={(e) =>
                handleUpdateDetails("contact_pincode", e.target.value)
              }
              type="text"
              placeholder={t("Enter Postal Code")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6 pt-2 input-width">
            {!countries?.length ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label mb="8px">
                  {t("User's Country")} <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Select Country")}
                  color="#1a1a1aad"
                  fontWeight="500"
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={activeMemberDetails?.country}
                  onOptionClick={(item) => {
                    handleUpdateDetails("contact_country", item?.value);
                    handleUpdateDetails("contact_country", item?.value);
                    if (activeMemberDetails?.contact_country !== item?.value) {
                      handleUpdateDetails("contact_city", "");
                      dispatch(fetchCities(item?.value));
                    }
                  }}
                  options={countries}
                />
              </>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">
              {t('Membership')} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size="middle"
              value={
                activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null
                  ? t("Free Subscription")
                  : activeMemberDetails?.subscriptions?.[0]?.subscriptionId
              }
              defaultValue="Select Plan"
              onChange={(value) =>
                handleUpdateDetails("subscription_plan_id", value)
              }
              style={{ width: "100%" }}
              options={optionsPlan()}
            />
            {errors.subscription_plan_id && (
              <ErrorMessage>{t(errors.subscription_plan_id)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            {isFetchingCities ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <label mb="8px">
                  {t("User's City")} <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Dropdown
                  allowSearch
                  variant="outlinedGreen"
                  defaultText={t("Select City")}
                  color="#1a1a1aad"
                  fontWeight="500"
                  width="100%"
                  height="54px"
                  Icon="none"
                  selectedValue={activeMemberDetails?.city}
                  onOptionClick={(item) =>
                    handleUpdateDetails("contact_city", item?.value)
                  }
                  options={cities}
                />
              </>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label>{t('Payment Mode')}</label>
            <Select
              size={"middle"}
              value={activeMemberDetails?.paymentMode || "Selecteer type"}
              defaultValue="Select type"
              onChange={(value) => handleUpdateDetails("paymentMode", value)}
              style={{ width: "100%", height: "54px" }}
              options={options}
              disabled={
                activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null
                  ? true
                  : false ||
                    activeMemberDetails?.subscriptions?.[0]?.subscriptionId ===
                      "free"
                  ? true
                  : false
              }
            />
            {errors.paymentMode && (
              <ErrorMessage>{t(errors.paymentMode)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">{t('Customer Account name')}</label>
            <input
              value={activeMemberDetails?.customerAccountName}
              onChange={(e) =>
                handleUpdateDetails("customer_acc_name", e.target.value)
              }
              type="text"
              placeholder={t("Enter customer account name")}
              className="input-box"
              disabled={
                activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null
                  ? true
                  : false ||
                    isManualPay ||
                    activeMemberDetails?.subscriptions?.[0]?.subscriptionId ===
                      "free"
                  ? true
                  : false
              }
            />
            {errors.customer_acc_name && (
              <ErrorMessage>{t(errors.customer_acc_name)}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width">
            <label mb="8px">{t('Customer IBAN')}</label>
            <input
              value={activeMemberDetails?.customerIBAN}
              onChange={(e) =>
                handleUpdateDetails("customer_iban", e.target.value)
              }
              type="text"
              placeholder={t("Enter customer IBAN")}
              className="input-box"
              disabled={
                activeMemberDetails?.subscriptions?.[0]?.subscriptionId === null
                  ? true
                  : false ||
                    isManualPay ||
                    activeMemberDetails?.subscriptions?.[0]?.subscriptionId ===
                      "free"
                  ? true
                  : false
              }
            />
            {errors.customer_iban && (
              <ErrorMessage>{t(errors.customer_iban)}</ErrorMessage>
            )}
          </div>
          {/*<div className="col-lg-6 pt-2 mb-4 input-width">
            <label mb="8px" >
            Lidmaatschapsdatum
            </label>
            <input
            value={formattedMembershipDate}
            onChange={(value) =>  handleUpdateDetails("membership_date", value)}
            type="text"
              placeholder=""
              className="input-box"
            />
            {errors.membership_date && (
              <ErrorMessage>{errors.membership_date}</ErrorMessage>
            )}
            </div>*/}
        </div>
        {getRenderButton()}
        <br />
      </div>
      <DefaultModal
        show={isUpdatedSuccessfully}
        onHide={() => dispatch(setCustomerUpdateStatus(false))}
        className="add-mosque-modal"
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div>
          <Icon
            iconName="modalClose"
            iconSize="xs"
            style={{ marginLeft: "auto" }}
            onClick={() => dispatch(setCustomerUpdateStatus(false))}
          />
          <div className="text-center add-mosque-modal-content">
            <Icon
              iconName="congratulations"
              style={{ justifyContent: "center" }}
            />
            <div>{t('Congratulations!')}</div>
            <Box variant="text3" color="#7E7E7E">
              {t('We have saved the details of the member. You can')} <br />
              {t('make further edits anytime.')}
            </Box>
            <button onClick={() => dispatch(setCustomerUpdateStatus(false))}>
              {t('Continue')}
            </button>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default EditForm;
