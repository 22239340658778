import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import axios from "axios";
import uploadImg from "./cloud_upload.svg";
import { truncate } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { 
  startUpload, 
  updateUploadProgress, 
  uploadComplete, 
  uploadFailed ,
  refetchTempUsers
} from "../../../uploadProgressReducer";
import { showNotification } from "../../../../../util/Utility";

const UPLOAD_URL = process.env.REACT_APP_API_URL === 'https://api.mosqapp.com/'?'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-99713b12-6756-4e42-a396-dd93c0e39985/sample/hello':'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-41fb4496-d63f-4bc8-839b-4adef1542999/sampleStaging/helloStaging'

function UploadXls({ refetchCustomerList }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  // Get progress from Redux
  let progress = useSelector((state) => state.uploadReducer.progress);
  
  
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    event.target.value = "";
    dispatch(startUpload());
    setIsUploading(true);
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("entityId", activeEntity._id);
  
    try {
      const res = await axios.post(
        `${UPLOAD_URL}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data","X-Upload-Type": "new","x-env":process.env.REACT_APP_API_URL },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            
            // Update global progress bar via Redux
            dispatch(updateUploadProgress(percentCompleted));
            console.log("Real-time Progress:", percentCompleted);
          },
        }
      );
  
      dispatch(uploadComplete({
        uploadedFile: truncate(file.name, { length: 20 }),
        uploadedFileLink: res.data?.data?.link,
      }));
      dispatch(refetchTempUsers())
      // Upload complete, set progress to 100%
      if(res.status === 200)
           {
            
           }
  
    } catch (error) {
      console.error("File upload error:", error);
      showNotification('error',t(error.response?.data?.message) || 'File upload failed')
      dispatch(uploadFailed());
  
    } finally {
      setIsUploading(false);
    }
  };
  
  

  return (
    <div style={{ marginTop: '30px' }}>
      <input
        id="xls-upload"
        type="file"
        onChange={uploadFile} 
        style={{ display: "none" }}
        accept=".xlsx, .xls"
      />
      <label
        htmlFor="xls-upload"
        className={isUploading ? "gray-bt" : "fill-bt"}
        style={{
          height: "auto", width: "224px", textAlign: 'center', padding: "20px 0px",
          pointerEvents: isUploading ? 'none' : '', color: 'white'
        }}
      >
        {t("Browse File")}
      </label>

    </div>
  );
}

export default UploadXls;
