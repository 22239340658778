import React, { useState, useEffect } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import Buttons from "../../../../../components/Buttons";
import styled from "styled-components";
import useOutsideClick from "../../../../../customHooks/useOutsideClick";
import Label from "../../../../../components/Label";
import { useTranslation } from "react-i18next";

const Container = styled(Box)`
  height: 320px;
  min-height: 320px;
  max-height: 320px;
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
  }
`;

const IconContainer = styled(Icon)`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "unset")};
`;

const DropdownItemBox = styled(Box)`
  position: absolute;
  background: rgb(245, 245, 245);
  z-index: 99;
  padding: 15px;
  margin-left: -10px;
  border-radius: ${(props) => (props.isOpen ? "0 0 8px 8px" : "8px")};
`;

const SearchInput = styled.input`
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Pill = styled(Box)`
  background-color: #D0004B;
  color: white;
  border-radius: 20px;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 8px;
`;

const CheckBoxFilterBy = ({ value, options, onChange }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [iconTheme, setIconTheme] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // State for search term

    const setInitialValue = () => {
        setOpen(false);
    };

    const ref = useOutsideClick(() => setInitialValue());

    useEffect(() => {
        if (value.length) {
            setSelectedItems([...value]);
        }
    }, [value]);

    useEffect(() => {
        checkIconFilled();
    }, [selectedItems]);

    const onChangeSelection = (keyName, keyValue) => {
        let filteredData = [...selectedItems];
        const data = filteredData.filter((item) => item[keyName] === keyValue);

        if (data.length > 0) {
            // Remove the item from the array if it is already selected
            const filterData = filteredData.filter((item) => item[keyName] !== keyValue);
            setSelectedItems(filterData);
        } else {
            // Check if the limit of selected items (5) is reached
            if (selectedItems.length < 5) {
                let updatedFilters = {};
                updatedFilters[keyName] = keyValue;
                setSelectedItems([...selectedItems, updatedFilters]); // Add the item to the array
            } else {
                // Optionally, show a message or alert
                alert("You can only select up to 5 items.");
            }
        }
    };

    const checkIconFilled = () => {
        if (selectedItems.length) {
            setIconTheme({ theme: "filled" });
        } else {
            setIconTheme({});
        }
    };

    const getState = (data) => {
        const filteredData = selectedItems.filter((item) => item[data?.key_name] === data.value);
        return !!filteredData.length;
    };

    const handleOpenChange = (e, flag) => {
        setOpen(flag);
        onChange(selectedItems);
    };

    const checkboxRender = () => {
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase()) // Filter based on search term
        );

        const groups = filteredOptions.reduce((result, option, index) => {
            if (index % 10 === 0) {
                result.push(filteredOptions.slice(index, index + 10));
            }
            return result;
        }, []);

        return (
            <DropdownItemBox value={selectedItems} isOpen={open}>
                {/* Search Input Field */}
                <SearchInput
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term on change
                />
                <Container>
                    {groups.map((group, i) => (
                        <Box key={i}>
                            {group.map((label) => (
                                <Box display="flex" alignItems="center" key={label?.key_name}>
                                    <Checkbox
                                        isWhite
                                        checked={getState(label)}
                                        value={label?.value}
                                        style={{ display: "flex", padding: "10px 0", marginRight: "10px" }}
                                        onChange={() => onChangeSelection(label?.key_name, label?.value)}
                                    />
                                    {label?.label}
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Container>
                <Box display="flex" gridGap="10px">
                    <Buttons minWidth="90px" minHeight="32px" type="outlineColor" text="Annuleren" onClick={() => setOpen(false)} />
                    <Buttons minWidth="90px" minHeight="32px" text="Toepassen" onClick={(e) => handleOpenChange(e, false)} />
                </Box>
            </DropdownItemBox>
        );
    };

    return (
        <Box position="relative" ref={ref}>
            <Box onClick={() => setOpen(!open)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Label variant="text8" color="#7E7E7E">{t('Filter By')}</Label>
                {selectedItems.length > 0 && (
                    <Pill>{selectedItems.length}</Pill> 
                )}
                <IconContainer iconName="arrowBottom" p="10px" isOpen={open} />
            </Box>
            {open && checkboxRender()}
        </Box>
    );
};

export default CheckBoxFilterBy;
