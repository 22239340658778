import React from "react";
import ProgressBar from "./ProgressBar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const TrackModal = ({ item }) => {
  const user = useSelector((state) => state.mosqueReducer.agent);
  const {t} = useTranslation()
  const renderGrowth = (cur, prev) => {
    let percentage = 0;
    if (prev !== 0) {
      percentage = (prev / cur) * 100;
    }
    return Math.abs(percentage || 0).toFixed(0);
  }

  return (
    <div className="track-section">
      <h6 className="text-center">Hi {user?.contact_name}, {t('your goal is')} </h6>
      <h4 className="text-center">{renderGrowth(item?.targetAmount, item?.total_amount_paid)}% {t("complete")}</h4>
      <div className="track-bg">
        <p className="text-center">{t("Being devoted to learning, but subjected to the passing of time in order to engage in work and endure great hardship.")} </p>
        <ProgressBar item={item} />
        <div className="d-flex justify-content-around text-center pt-4">
          <div>
            <span>{t("Total Doners")}</span>
            <br />
            <b>{item?.total_payee_count}</b>
          </div>
          <div>
            <span>{t("Donation Collected")}</span>
            <br />
            <b>{item?.total_amount_paid}</b>
          </div>
          <div>
            <span>{t("Highest Donation")}</span>
            <br />
            <b>{item?.highest_donation}</b>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button className="fill-bt">
            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/payment-link.svg" />
            {t("Payment Link")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackModal;
