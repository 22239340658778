import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { showNotification } from "../../../../../util/Utility";
import { useTranslation } from "react-i18next";

const DownloadErrorExcel = ({ errorUsers }) => {
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const [isDownloading, setIsDownloading] = useState(false)
  const {t} = useTranslation()
  const handleErrorDownload = async () => {
    try {
      if (errorUsers.length === 0) {
        alert("No error data available to download.");
        return;
      }
      setIsDownloading(true)
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}agent/entity/${activeEntity?._id}/error-excel`,
        {errorUsers: errorUsers}, 
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
  
      // Extract base64 string and file name from the response
      const { file, fileName } = res.data;
  
      if (!file) throw new Error("No file data received");
  
      // Convert base64 string to a Blob
      const byteCharacters = atob(file); // Decode Base64
      const byteNumbers = new Uint8Array(byteCharacters.length);
      
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      
      const blob = new Blob([byteNumbers], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
      // Create a URL and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "sample.xlsx"; // Use provided file name or default
      document.body.appendChild(a);
      a.click();
  
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
      showNotification("error", "Error downloading sample");
    }finally{
      setIsDownloading(false)
    }
  };
  

  return (
    <button onClick={handleErrorDownload} className="proceed-btn">
      {isDownloading?t('Downloading...'):t('Download Errors')}
    </button>
  );
};

export default DownloadErrorExcel;
