import React from "react";
import OnboardingTab from "./components/OnboardingTab";
import "../../../../css/home.css";
import ErrorMsg from "./components/ErrorMsg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Onboarded = () => {
  const is_error = useSelector((state) => state.customerReducer.is_error);
  const {t} = useTranslation()
  const error_message = useSelector(
    (state) => state.customerReducer.error_message
  );

  return (
    <div className="onboarding-bg">
      <h2 id="onboarding-state">{t('Sign Up')}</h2>
      <div className="tab_bg"  id="onboarding-state2">
        <OnboardingTab />
      </div>
      {is_error && <ErrorMsg messageText={error_message} />}
    </div>
  );
};

export default Onboarded;
