import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
const Banner = ({isResetLinkSend,setIsResetLinkSend}) => {
  const [isReset, setIsReset] = useState(false);

  const handleClick = () => {
    window.location.href = "/";
  };
  const {t} = useTranslation()
useEffect(() => {
  setIsReset(isResetLinkSend);
},[isResetLinkSend]);
console.log("reset value is ",isReset);
  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleClick()} style={{background: "white"}}>
          <img style={{ height: "70px", width: "auto" }} src="/assets/mosqapp-logo-new.svg" alt="logo"/>
        </div>
        <div className="login_content">
          <h3>{t('Welcome!')}</h3>
          <p>{isReset ? t('Check your Email') : t('Enter Email To Reset Password')}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
