import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ErrorMsg = ({ messageText }) => {
  const {t} = useTranslation()
  return (
    <div className="error-box mt-4"><p>{t(messageText)}<Link to="/login">{t('Log In Now')}</Link></p></div>
  )
}

export default ErrorMsg;
