import React, { useEffect, useState, useRef } from "react";
import Banner from "../Banner/Banner";
import PlanCard from "../PlanCard/PlanCard";
import axios from "axios";
import { baseUrl } from "../../app.config";
import ContactNumberInput from "../ContactNumberInput";
import PhoneNumberInput from "../PhoneNumberInput";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CustomErrorMessage from "../CustomErrorMessage";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useNavigation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import validatePhoneNumber from "../../helpers/validatePhoneNumber";
import Loader from "../Loader2";
import Header from "../Header2/Header";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Dropdown from "../../components/Dropdown";
import CitySelectUser from "../CustomDropdown/CitySelectUser";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import styled from "styled-components";

let nameRegex = /^[A-Za-z\s]+$/;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(nameRegex, "Only letters allowed")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(nameRegex, "Only letters allowed")
    .required("Last Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email address"
    )
    .required("Email is required"),
  dob: Yup.string().required("Date of Birth is required"),
  gender: Yup.string().required("Please select any one"),
});

const initialValues = {
  email: "",
  contact_country: "",
  contact_city: "",
  dob: "",
  gender: "",
};

const customCountries = [
  { label: "Netherlands", value: "Netherlands" },
  { label: "Germany", value: "Germany" },
  { label: "France", value: "France" },
  { label: "Austria", value: "Austria" },
  { label: "Belgium", value: "Belgium" },
];

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

function CustomForm() {
  // const mosqueId = "65dc9e818c40d70018da5b1d";
  const { mosqueId } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(null);
  const [countryCode, setCountryCode] = useState({
    value: "+31",
    label: "+31 (Netherlands)",
    code: "NL",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState("nl");
  const [mosque, setMosque] = useState(null);
  const [userId, setUserId] = useState(null);
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);

  const { t } = useTranslation();
  const onceRef = useRef(false);
  const maxDate = dayjs().subtract(18, "year");

  useEffect(() => {
    onceRef.current = true;
    const getMosqueDetails = async () => {
      try {
        const res = await axios.get(
          `${baseUrl}/v1/entity?entityId=${mosqueId}`
        );
        console.log("custom-----------", res.data);
        const selectedMosque = res?.data[0];
        setMosque(selectedMosque);
        setPlans(
          selectedMosque.subscriptions
            ? selectedMosque.subscriptions
            : selectedMosque.subscription
        );

        const user = JSON.parse(sessionStorage.getItem('standaloneUser'))
        if(user)
        {
          const subs = selectedMosque.subscriptions
          ? selectedMosque.subscriptions
          : selectedMosque.subscription
          const preSelectedPlan = subs.find(plan=>plan._id === user.subscriptionId)
          setSelectedPlan(preSelectedPlan)
        }
      } catch (error) {
        console.error(error);
      }
    };
   
    getMosqueDetails();


    const prePopulateDetails = ()=>{
      const user = JSON.parse(sessionStorage.getItem('standaloneUser'))
      if(!user) return;
      initialValues.email = user.emailAddress
      initialValues.name = user.name
      setPhone(user.phoneNumber)
    }
    // prePopulateDetails()

  }, []);


  const captureForm = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const form = document.querySelector(".form");
      if (!form) {
        return;
      }
      const canvas = await html2canvas(form, {
        scrollY: -window.scrollY,
        width: form.scrollWidth,
        height: form.scrollHeight,
      });

      const formImage = canvas.toDataURL("image/png");
      if (localStorage.getItem("formImage") !== null) {
        localStorage.removeItem("formImage");
      }
      localStorage.setItem("formImage", formImage);
    } catch (error) {
      alert(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (!validatePhoneNumber(phone, countryCode.code)) {
        setPhoneError(true);
        return;
      }
      // printDocument()
      const userInput = {
        name: values.firstName + "," + " " + values?.lastName,
        emailAddress: values.email,
        country: values?.contact_country,
        city: values?.contact_city,
        dob: values?.dob,
        gender: values?.gender,
        password: uuidv4(),
        phoneNumber: phone,
        countryCode: countryCode.value,
        language,
        isDigitalFormOnboarded: true,
        isMobileOnBoarded: false,
      };
      console.log(userInput);
      setIsLoading(true);
      const signUpRes = await axios.post(
        `${baseUrl}/v1/create-account`,
        userInput
      );
      console.log("signup res -->", signUpRes);
      setUserId(signUpRes?.data._id);
      localStorage.setItem("userIdDigital", signUpRes?.data._id);
      localStorage.setItem("mosqueDigital", JSON.stringify(mosque));
      const token = signUpRes.data?.token;
      const obj = {
        subscriptionId: selectedPlan?._id,
        entityId: mosqueId,
        paymentMode: "automatic",
        country: values?.contact_country,
        city: values?.contact_city,
        organisationId: mosque?.organisation_id,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${baseUrl}/v1/add-user-subscription`, obj, config);
      const paymentId = uuidv4();
      const paymentPayload = {
        userId: signUpRes?.data._id,
        paymentMode: "automatic",
        amount: 0.01,
        subscriptionId: uuidv4(),
        paymentId,
        entityId: mosqueId,
        paymentMethod: "ideal",
      };

      const res = await axios.post(
        `${baseUrl}/v1/create-transaction?origin=web&mosqueId=${mosqueId}`,
        paymentPayload,
        config
      );
      console.log("payment-->", res.data);
      await captureForm();
      const storeInfo = {
        ...userInput,
        ...obj
      }
      sessionStorage.setItem('standaloneUser',JSON.stringify(storeInfo))
      setIsLoading(false);
      const redirectUrl = res.data?.requiredAction?.redirectURL;
      console.log(redirectUrl);
      window.open(redirectUrl, "_self");
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
      setIsLoading(false);
    }

    console.log(values, phone, selectedPlan.plan_name);
    // Add your submit logic here
  };

  // Method to show min and max date in date picker
  const disableDates = (current) => {
    const minDate = dayjs().subtract(100, "year"); // 100 years ago
    const maxDate = dayjs().subtract(18, "year");  // 18 years ago
    return current && (current.isBefore(minDate, "day") || current.isAfter(maxDate, "day"));
  };

  const getForm = () => {
    return mosque ? (
      <>
        <Header setLanguage={setLanguage} />
        <div className="form">
          <Banner mosque={mosque} />
          <main className="form-content">
            <h1 className="sub-heading">{t("Enter Details")}</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-group-1">
                    <div>
                      <label className="label" style={{ color: "#7e7e7e" }}>
                        {t("First Name")}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <Field
                        name="firstName"
                        placeholder={t("Enter First Name")}
                        className="input-box"
                        type="text"
                      />
                      <CustomErrorMessage
                        name="firstName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div>
                      <label className="label" style={{ color: "#7e7e7e" }}>
                        {t("Last Name")}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <Field
                        name="lastName"
                        placeholder={t("Enter Last Name")}
                        className="input-box"
                        type="text"
                      />
                      <CustomErrorMessage
                        name="lastName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-2">
                    <div>
                      <label className="label" style={{ color: "#7e7e7e" }}>
                        {t("E-mail address")}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <Field
                        name="email"
                        placeholder={t("Enter E-mail address")}
                        className="input-box"
                        type="text"
                      />
                      <CustomErrorMessage
                        name="email"
                        className="error-message"
                      />
                    </div>
                    <div>
                      <label className="label" style={{ color: "#7e7e7e" }}>
                        {t("Contact Number")}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      {/* <ContactNumberInput
              setCountryCode={setCountryCode}
              phone={phone}
              setPhoneError={setPhoneError}
              setPhone={setPhone}
            /> */}
                      <PhoneNumberInput
                        setCountryCode={setCountryCode}
                        phone={phone}
                        setPhoneError={setPhoneError}
                        setPhone={setPhone}
                      />

                      {phoneError && (
                        <div className="error-message">
                          <IoIosInformationCircleOutline />{" "}
                          {t("Enter valid phone number")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group form-group-2">
                    <div className="col-lg-6 pt-2 ">
                      {!countries?.length ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100%" }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        <>
                          <label
                            isMandatory
                            className="label"
                            style={{ color: "#7e7e7e" }}
                          >
                            {t("User's Country")}{" "}
                            <span style={{ color: "#FE1717" }}>*</span>
                          </label>
                          <Dropdown
                            allowSearch
                            variant="outlinedGreen"
                            defaultText={
                              values?.contact_country || t("Selecteer Land")
                            }
                            color="#1a1a1aad"
                            fontSize="18px"
                            fontWeight="500"
                            width="100%"
                            height="54px"
                            Icon="none"
                            selectedValue={values?.contact_country}
                            onOptionClick={(item) => {
                              console.log("item", item);
                              setFieldValue("contact_country", item?.value);
                            }}
                            options={customCountries}
                          />
                        </>
                      )}
                    </div>
                    <div className="col-lg-6 pt-2 ">
                      <label
                        isMandatory
                        className="label"
                        style={{ color: "#7e7e7e" }}
                      >
                        {t("User's City")}{" "}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <CitySelectUser
                        defaultText={t("Select City")}
                        setFieldValue={setFieldValue}
                        users={values}
                        cities={cities}
                        source={"custom-form"}
                      />
                    </div>
                  </div>
                  <div className="form-group form-group-2">
                    <div className="col-lg-6 pt-2 ">
                      <label
                        className="label"
                        isMandatory
                        style={{ color: "#7e7e7e" }}
                      >
                        {t("Date of Birth")}{" "}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <DatePickerBox>
                        <DatePicker
                         placeholder="DD-MM-YYYY"
                         value={values?.dob ? dayjs(values.dob, "DD-MM-YYYY") : null}
                         onChange={(date, dateString) => setFieldValue("dob", dateString)}
                         format="DD-MM-YYYY"
                         disabledDate={disableDates}
                         defaultPickerValue={maxDate}
                        />
                      </DatePickerBox>
                      <CustomErrorMessage
                        name="dob"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-lg-6 pt-2 ">
                      <label
                        className="label"
                        isMandatory
                        style={{ color: "#7e7e7e" }}
                      >
                        {t("Gender")}{" "}
                        <span style={{ color: "#FE1717" }}>*</span>
                      </label>
                      <div
                        style={{
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            checked={values?.gender === "male"}
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                          />
                          {t("Male")}
                        </label>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                            checked={values?.gender === "female"}
                            onChange={(e) =>
                              setFieldValue("gender", e.target.value)
                            }
                          />
                          {t("Female")}
                        </label>
                      </div>
                      <CustomErrorMessage
                        name="gender"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="label" style={{ color: "#7e7e7e" }}>
                      {t("Payment Mode")}
                      <span style={{ color: "#FE1717" }}>*</span>
                    </label>
                    <Field
                      name="paymentMode"
                      placeholder="Enter Name"
                      disabled
                      value={t("Automatic")}
                      className="input-box"
                      type="text"
                    />
                  </div>
                  {/* </div> */}
                  {/* <div className="line"></div> */}
                  {/* <h1 className="sub-heading">{t('Payment Details')}</h1>
                   */}
                  <div className="plans">
                    {plans?.length > 0 ? (
                      plans?.map((plan) => (
                        <PlanCard
                          key={plan._id}
                          selectedPlan={selectedPlan}
                          setSelectedPlan={setSelectedPlan}
                          plan={plan}
                        />
                      ))
                    ) : (
                      <label className="label">{t("No active plans")}..</label>
                    )}
                  </div>
                  <div className="btn-container">
                    <button
                      type="submit"
                      className="submit-btn"
                      disabled={!selectedPlan || isLoading}
                      style={{
                        background:
                          selectedPlan && !isLoading ? "#D0004B" : "#a0aec0",
                      }}
                    >
                      {t("Proceed to Auto Debit")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
        </div>
      </>
    ) : (
      <Loader />
    );
  };

  return getForm();
}

export default CustomForm;
