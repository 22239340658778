import React, { useRef, useState, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Congratulation from "./Congratulation";
import SelectMosqueBoxPlan from "./SelectMosqueBoxPlan";
import Label from "./../../../../../components/Label/Label";
import Icon from "./../../../../../components/Icon/index";
import Box from "./../../../../../components/Box/Box";
import ErrorMessage from "../../../../../components/ErrorMessage";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Canvas from "../../../../../components/Canvas/Canvas";
import { setOnboardingDetails } from "../../../customerAction";
import { alignItems, textAlign } from "styled-system";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  Bedrijfsn: Yup.string().required("Bedrijfsnaam: is vereist."),
  kvk: Yup.string().required("KVK nummer: is vereist."),
  Contactnaam: Yup.string().required("Contactnaam: is vereist."),
  emailadres: Yup.string()
    .email("invalid email")
    .required("Contact Email Address: is required."),
  NaamInitialen: Yup.string().required("Naam Initialen: is vereist."),
  Achternaam: Yup.string().required("Achternaam: is vereist."),
  Plaats: Yup.string().required("Plaats: is vereist."),
  Datum: Yup.string().required("Datum: is vereist."),
  checkbox: Yup.boolean().oneOf(
    [true],
    "Message: I agree with the terms and conditions of Mosqapp"
  ),
});

const emStyle = {
  display: "block",
  marginBottom: "20px",
  color: "#7E7E7E",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: 400,
  textAlign: "left",
};

const MosqueStepThree = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onboardingDetails = useSelector(
    (state) => state.customerReducer.onboardingDetails
  );
  const [submitting, setSubmitting] = useState(false);
  const [isCanvasDrawn, setIsCanvasDrawn] = useState(null);
  const [canvasError, setCanvasError] = useState(false);
  const [changedFields, setChangedFields] = useState({
    Bedrijfsn: false,
    kvk: false,
    Contactnaam: false,
    emailadres: false,
    NaamInitialen: false,
    Achternaam: false,
    Plaats: false,
    Datum: false,
    checkbox: false,
  });
  const [signature, onSignatureDrawn] = useState(null);
  // const formRef = useRef(null);
  const { isAppliedTnC } = onboardingDetails;
  // const formRef = ref;
  const handleSubmit = async (values) => {
    console.log("handle sunit called");
    try {
      // if (!isCanvasDrawn) {
      //   setCanvasError(true);
      //   return;
      // }

      setSubmitting(true);
      // Perform form submission logic here
      console.log(values);
      // Set submitting to false after successful submission
      setSubmitting(false);
    } catch (error) {
      // Handle form submission error
      console.error(error);
      setSubmitting(false);
    }
  };
  // React.useImperativeHandle(ref, () => ({
  //   submit: () => {
  //     if (formRef.current) formRef.current.submit();
  //   }
  // }))
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };
  const formik = useFormik({
    initialValues: {
      // Bedrijfsn: "",
      // kvk: "",
      // // Contactnaam: "",
      // // emailadres: "",
      // NaamInitialen: "",
      // Achternaam: "",
      // Plaats: "",
      // Datum: getCurrentDate() || "",
      checkbox: false,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const { values, errors } = formik;

  const is_onboarded = useSelector(
    (state) => state.customerReducer.is_onboarded
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setOnboardingDetails(name, value));
    formik.handleChange(e);
  };
  // React.useImperativeHandle(ref, () => ({
  //   submitForm: () => {
  //     if (formik) {
  //       formik.handleSubmit(); // Call handleSubmit from formik
  //     }
  //   },
  // }));
  useEffect(() => {
    if (
      // values.Bedrijfsn !== "" &&
      // values.kvk !== "" &&
      // // values.Contactnaam !== "" &&
      // // values.emailadres !== "" &&
      // values.NaamInitialen !== "" &&
      // values.Achternaam !== "" &&
      // values.Plaats !== "" &&
      // values.Datum !== "" &&
      // isCanvasDrawn &&
      Object.keys(errors).length <= 10
    ) {
      if(values.checkbox)
      props.setIsStepThreeErrors(false);
      else
      props.setIsStepThreeErrors(true);
    } else props.setIsStepThreeErrors(true);
  }, [values, isCanvasDrawn, props, errors]);

  console.log('step 3',errors, values);
  

  useEffect(() => {
    dispatch(setOnboardingDetails("signUrl", signature));
  }, [signature, dispatch]);

  return (
    <div style={{ margin: "30px 0px", overflow: "hidden" }}>
      {!is_onboarded ? (
        <>
          {/* <iframe  ref={iframeRef} src="https://www.cognitoforms.com/f/n1YCB8wHoUC9h1vFW8FUrQ/12" 
        style={{ border: "0", width: "100%", height: "1200px", overflow: "hidden"}}></iframe>
        <script src="https://www.cognitoforms.com/f/iframe.js"></script> */}
          <div
            className="form-container stepThreeForm"
            style={{ marginBottom: "20px" }}
          >
            {/* <div
              className="form-logo"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src="assets/mosqapp-logo-new.svg" height="70px" alt="mosqapp-logo"></img>
            </div>
            <hr></hr> */}
            {/* <h4
              style={{
                fontWeight: 600,
                fontSize: "22px",
                color: "#1A1A1A",
                margin: "30px 0px",
              }}
            >
              By Moodus
            </h4> */}
            <form
              // ref={formRef}

              method="POST"
              // onSubmit={(e) => {
              //   console.log('submit form');
              //   e.preventDefault(); // Prevent default form submission behavior
              //   formik.handleSubmit(e); // Manually call Formik's handleSubmit
              // }}
              style={{ display: "flex", flexDirection: "column", gap: 28 }}
            >
              {/* <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="Bedrijfsn"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Company name")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Bedrijfsn"
                    name="Bedrijfsn"
                    value={formik.values.Bedrijfsn}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Bedrijfsn: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Bedrijfsn && formik.errors.Bedrijfsn && (
                    <ErrorMessage>{t(formik.errors.Bedrijfsn)}</ErrorMessage>
                  )}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="kvk"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    KVK nummer
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="kvk"
                    name="kvk"
                    value={formik.values.kvk}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, kvk: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.kvk && formik.errors.kvk && (
                    <ErrorMessage>{t(formik.errors.kvk)}</ErrorMessage>
                  )}
                </div>
              </div> */}
              {/* <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="Contactnaam"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    Contactnaam<span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Contactnaam"
                    name="Contactnaam"
                    value={formik.values.Contactnaam}
                    onChange={(e)=>{
                      handleChange(e);
                      setChangedFields({...changedFields,Contactnaam:true})
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Contactnaam && formik.errors.Contactnaam && (
                    <ErrorMessage>{formik.errors.Contactnaam}</ErrorMessage>
                  )}
                </div>

                <div style={{ flexGrow: 1 }}>
                  <label
                    htmlFor="emailadres"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    Contact emailadres
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="emailadres"
                    name="emailadres"
                    value={formik.values.emailadres}
                    onChange={(e)=>{
                    handleChange(e);
                      setChangedFields({...changedFields,emailadres:true})
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.emailadres && formik.errors.emailadres && (
                    <ErrorMessage>{formik.errors.emailadres}</ErrorMessage>
                  )}
                </div>
              </div> */}
              <div>
                <h6
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  MosqApp
                </h6>
                <p style={emStyle}>
                  {t(
                    `We offer you our membership administration tool free of charge. You can use our system as much as you want. We only ask for a fee for the transactions that actually take place. This covers bank and payment processor costs, as well as system and server maintenance costs. For this, we ask`
                  )}
                  &nbsp;
                  <strong style={{ color: "#1A1A1A", fontSize: "16px" }}>
                    €0,80&nbsp;(incl btw)
                  </strong>
                  &nbsp;per transactie.
                </p>
                <p style={emStyle}>
                  {t("Do you only use the membership administration and not  ")}
                  {t("the payment options?")}&nbsp;
                  <strong style={{ color: "#1A1A1A", fontSize: "16px" }}>
                    {t("Then MosqApp remains completely free!")}
                  </strong>
                </p>
                {/* <a
                  href={`https://staging.mosqapp.com/terms-and-conditions`}
                  style={{ color: "#3200FF", textDecoration: "underline" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("General Terms and Conditions MosqApp")}
                </a> */}
              </div>
              {/* <div>
                <h6
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  {t("Transaction Processing")}
                </h6>
                <p style={emStyle}>
                  {t(
                    `For transaction processing, Moodus uses Buckaroo payment services. The Buckaroo payment service consists of providing the possibility to carry out electronic payments. The agreement is entered into under the suspensive condition of acceptance of the customer by Buckaroo B.V.. The general terms and conditions of Buckaroo apply to this agreement, which you agree to. You declare that you have taken note of the general terms and conditions as part of the application procedure and as stated on the website and accept them.`
                  )}
                  &nbsp;{" "}
                  {t(
                    `The provision of the possibility to carry out electronic payment transactions. The agreement is`
                  )}
                  &nbsp;{" "}
                  {t(
                    `is entered into under the suspensive condition of the customer's acceptance by Buckaroo B.V. The General Terms and Conditions`
                  )}
                  &nbsp;{" "}
                  {t(
                    `of Buckaroo apply to this agreement, which you agree to. You declare that you have knowledge of`
                  )}
                  &nbsp;{" "}
                  {t(
                    `the General Terms and Conditions as part of the application procedure and as`
                  )}
                  &nbsp; {t(`stated on the website`)}
                  &nbsp;
                  <Link
                    to="https://www.buckaroo.nl/algemene-voorwaarden"
                    rel="noreferrer"
                    target="_blank"
                    style={{ color: "#3200FF", textDecoration: "underline" }}
                  >
                    https://www.buckaroo.nl/algemene-voorwaarden
                  </Link>
                  &nbsp;{t("and to accept them.")}
                </p>
              </div> */}
              <div className="checkbox">
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <input
                    type="checkbox"
                    id="form-check-box"
                    name="checkbox"
                    value={t(formik.values.checkbox)}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, checkbox: true });
                    }}
                    style={{
                      marginRight: "10px",
                      height: "24px",
                      width: "50px",
                      opacity: "60%",
                      border: "2px solid #D9DBE9",
                      borderRadius: "4px",
                    }}
                  ></input>
                  <label
                    htmlFor="form-check-box"
                    style={{ ...emStyle, display: "inline", color: "#1A1A1A" }}
                  >
                    
                      {t("I agree to the")} {" "}
                        <a
                        target="_blank"
                        href="https://staging.mosqapp.com/terms-and-conditions"
                      >
                        {t('Terms and conditions')}
                      </a>
                      {" "}
                      {t("and")}
                      {" "}
                      <a
                          target="_blank"
                          href="https://staging.mosqapp.com/privacy-policy"
                        >
                        {t('Privacy Policy')}
                      </a> {" "} of Mosqapp.
                    
                  </label>
                </div>
                {changedFields.checkbox && formik.errors.checkbox && (
                  <ErrorMessage>{t(formik.errors.checkbox)}</ErrorMessage>
                )}
              </div>
              {/* <div
                className="second-group row"
                // style={{ display: "flex", gap: "20px", flexWrap:'wrap' }}
              >
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="NaamInitialen"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Initials")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="NaamInitialen"
                    name="NaamInitialen"
                    value={formik.values.NaamInitialen}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({
                        ...changedFields,
                        NaamInitialen: true,
                      });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.NaamInitialen &&
                    formik.errors.NaamInitialen && (
                      <ErrorMessage>
                        {t(formik.errors.NaamInitialen)}
                      </ErrorMessage>
                    )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="Achternaam"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Last Name")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Achternaam"
                    name="Achternaam"
                    value={formik.values.Achternaam}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Achternaam: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Achternaam && formik.errors.Achternaam && (
                    <ErrorMessage>{t(formik.errors.Achternaam)}</ErrorMessage>
                  )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3 mb-sm-2">
                  <label
                    htmlFor="Plaats"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Place")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Plaats"
                    name="Plaats"
                    value={formik.values.Plaats}
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Plaats: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box"
                  />
                  {changedFields.Plaats && formik.errors.Plaats && (
                    <ErrorMessage>{t(formik.errors.Plaats)}</ErrorMessage>
                  )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-3">
                  <label
                    htmlFor="Datum"
                    style={{
                      display: "block",
                      fontWeight: 500,
                      color: "#7E7E7E",
                      fontSize: "16px",
                    }}
                  >
                    {t("Datum")}
                    <span style={{ color: "#FE1717" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="Datum"
                    name="Datum"
                    value={formik.values.Datum}
                    disabled
                    onChange={(e) => {
                      handleChange(e);
                      setChangedFields({ ...changedFields, Datum: true });
                    }}
                    // style={{ width: "100%", padding: "2px 5px" }}
                    className="input-box calendar"
                  />
                  {changedFields.Datum && formik.errors.Datum && (
                    <ErrorMessage>{t(formik.errors.Datum)}</ErrorMessage>
                  )}
                </div>
              </div> */}
              {/* <div className="canvas" style={{ position: "relative" }}>
                <label
                  htmlFor="canvas"
                  style={{
                    display: "block",
                    fontWeight: 500,
                    color: "#7E7E7E",
                    fontSize: "16px",
                  }}
                >
                  {t("Signature")}
                  <span style={{ color: "#FE1717" }}>*</span>
                </label>
                <Canvas
                  id="canvas"
                  setCanvasError={setCanvasError}
                  setIsCanvasDrawn={setIsCanvasDrawn}
                  onSignatureDrawn={onSignatureDrawn}
                />
              </div>
              {canvasError && (
                <ErrorMessage style={{ marginTop: "-20px" }}>
                  {t(" Signature: is required.")}
                </ErrorMessage>
              )} */}
            </form>
          </div>
          {/* <Label variant="text2">{t("Your subscription includes:")}</Label> */}
          {/* <Box my="25px">
            <Box display="flex" alignItems="center">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Membership Management")}
                </Label>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" my="20px">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Payment Management")}
                </Label>
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Icon iconName="fourSquare" mr="10px" />
              <Box>
                <Label variant="text5" fontWeight={500}>
                  {t("Payment Reminders via Email")}
                </Label>
              </Box>
            </Box>
          </Box>
          <Box>
            <a
              target="_blank"
              href="https://staging.mosqapp.com/terms-and-conditions"
            >
              {t('Terms and conditions')}
            </a>
            <br />
            <a
              target="_blank"
              href="https://staging.mosqapp.com/privacy-policy"
            >
             {t('Privacy Policy')}
            </a>
          </Box> */}
        </>
      ) : (
        <Congratulation />
      )}
    </div>
  );
});

export default MosqueStepThree;
