import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import { Select } from "antd";
import { getPaymentRecurringOption } from "../../../../../util/Utility";
import Icon from "../../../../../components/Icon/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import { updatePlan, updateAddedNewFlag } from "../../../mosqueAction";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { useTranslation } from "react-i18next";

const options = getPaymentRecurringOption();

function EditPlan({ editDetails }) {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const [isNext, setIsNext] = useState(false);
  const [details, updateDetails] = useState(editDetails);
  const [errors, setErrors] = useState({});

  const planDetails = useSelector((state) => state.mosqueReducer.memberPlans?.plan_details);
  const isAddedNew = useSelector((state) => state.mosqueReducer.memberPlans?.isAddedNew);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  const formValidation = () => {
    return !!planDetails?.plan_name.trim() &&
      !!planDetails?.amount.trim() &&
      !!planDetails?.recurring_type &&
      !!planDetails?.payment_day;
  }

  useEffect(() => {
    if (planDetails) {
      setIsNext(formValidation());
    }
  }, [planDetails])

  const optionPaymentDate = () => {
    let option = [];
    for (let i = 1; i <= 31; i++) {
      const data = {};
      data.label = i;
      data.value = i;
      option.push(data);
    }
    return option;
  }

  const handleChangeUser = (keyName, keyValue) => {
    setErrors({});
    setIsNext(true);
    const data = { ...details }
    data[keyName] = keyValue;
    updateDetails(data);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!details.plan_name) {
      isValid = false;
      error["plan_name"] = "Please enter plan name";
    }
    if (!details.amount) {
      isValid = false;
      error["amount"] = "Please enter amount";
    }
    if (!!details.amount && details?.amount?.toString()?.length > 5) {
      isValid = false;
      error["amount"] = "Amount not exceed to 99999";
    }
    if (!details.recurring_type) {
      isValid = false;
      error["recurring_type"] = "Please select recurring type";
    }
    if (!details.payment_day) {
      isValid = false;
      error["payment_day"] = "Please select payment day";
    }
    return {
      isValid,
      error
    };
  }

  const handleAddPlan = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {

      const trimmedDetails = {};
      // Trim each attribute and assign it to trimmedDetails object
      for (const key in details) {
        if (typeof details[key] === "string") {
          trimmedDetails[key] = details[key].trim();
        } else {
          trimmedDetails[key] = details[key];
        }
      }

      dispatch(updatePlan(details?._id, details));
    } else {
      setErrors(validate.error);
    }
  }

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = `/mosque/${activeEntity?._id}/subscriptions`
  };

  return (
    <div className="add_form">
      <div className="mosque-form">
        <div className="row">
          <div className="col-lg-6">
            <label>
              {t('Plan Name')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.plan_name}
              onChange={(e) => handleChangeUser("plan_name", e.target.value)}
              type="text"
              placeholder={t("Plan name")}
              className="input-box"
            />
            {errors.plan_name && <ErrorMessage>{t(errors.plan_name)}</ErrorMessage>}
          </div>
          <div className="col-lg-6 ">
            <label>
              {t('Plan Price')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.amount}
              onChange={(e) => handleChangeUser("amount", e.target.value)}
              type="text"
              placeholder="Plan price"
              className="input-box"
            />
            {errors.amount && <ErrorMessage>{t(errors.amount)}</ErrorMessage>}
          </div>

          <div className="col-lg-6 pt-5">
            <label>
              {t('Billing Frequency')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={details?.recurring_type || t("Select type")}
              defaultValue={t("Select type")}
              onChange={(value) => handleChangeUser("recurring_type", value)}
              style={{ width: "100%" }}
              options={options}
            />
            {errors.recurring_type && <ErrorMessage>{t(errors.recurring_type)}</ErrorMessage>}
          </div>
          <div className="col-lg-6 pt-5">
            <label>
              {t('Payment Date')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={details?.payment_day || t("Select date")}
              defaultValue={t("Select date")}
              onChange={(value) => handleChangeUser("payment_day", value)}
              style={{ width: "100%" }}
              options={optionPaymentDate()}
            />
            {errors.payment_day && <ErrorMessage>{t(errors.payment_day)}</ErrorMessage>}
          </div>
        </div>
        <Button
          type={!isNext ? "disabled" : "primary"}
          text={t("Save Details")}
          width="15%"
          mt="4"
          mx="auto"
          onClick={() => handleAddPlan()}
        />
        <br />
        <DefaultModal
          show={isAddedNew}
          onHide={() => dispatch(updateAddedNewFlag(false))}
          className="add-mosque-modal"
          centered={true}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div>
            <Icon
              iconName="modalClose"
              iconSize="xs"
              style={{ marginLeft: "auto" }}
              onClick={() => dispatch(updateAddedNewFlag(false))}
            />
            <div className="text-center add-mosque-modal-content">
              <Icon
                iconName="congratulations"
                style={{ justifyContent: "center" }}
              />
              <div>{t('Congratulations!')}</div>
              <div>
                {t('We have added the new plan. You can make edits to')} <br/>
                {t('existing plans anytime.')}
              </div>
              <button onClick={() => handleAddNew()}>{t('Edit plan')} </button>
            </div>
          </div>
        </DefaultModal>
      </div>
    </div>
  );
}

export default EditPlan;
