import React from 'react'
import "../../../../../css/admin.css"
import {CardData} from '../../../../../constants/AdminData';
import Icon from '../../../../../components/Icon';
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';

function PlanCard() {
    const {t} = useTranslation()
    const dashboardStats = useSelector(state => state.mosqueReducer?.dashboardStats)
    console.log("Dashboard Stats:", dashboardStats);
    const renderGrowth = (cur, prev) => {
        const diff = cur - prev;
        let percentage = 0;
        if (prev !== 0) {
            percentage = (diff / prev) * 100;
        }
        return Math.abs(percentage || 0).toFixed(2);
    }

    const renderIconName = (cur, prev) => {
        const diff = cur - prev;
        if (diff >= 0) {
            return 'upArrow';
        }
        return 'downArrow'
    }
    
    const current_amount = (dashboardStats?.current_month_total_revenue || 0).toFixed(2);
    const pending_amount = (dashboardStats?.current_month_pending_revenue || 0).toFixed(2);

    return (
        <div>
            <div className="row">
                <div className="col-lg-4 col-md-4">
                    <div className="chart-box">
                        <div className="d-flex">
                            <Icon
                                iconName="TotalDonation"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="15px"
                            />
                            <div>
                                <small>{t('Total')} <br/>{t('Income')}</small>
                                <h1>{`€${current_amount}`}</h1>
                                <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#00AC4F"
                                          iconName={renderIconName(dashboardStats?.current_month_total_revenue, dashboardStats?.last_month_total_revenue)}/>
                                    <label className={"green"}>
                                        {renderGrowth(dashboardStats?.current_month_total_revenue, dashboardStats?.last_month_total_revenue)}%
                                    </label>{t('This Month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="chart-box">
                        <div className="d-flex">
                            <Icon
                                iconName="PendingDonations"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="15px"
                            />
                            <div>
                                <small>{t('Income')} <br/>{t('In Progress')}</small>
                                <h1>{`€${pending_amount}`}</h1>
                                <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#FECB17"
                                          iconName={renderIconName(dashboardStats?.current_month_pending_revenue, dashboardStats?.last_month_pending_revenue)}/>
                                    <label className={"yellow"}>
                                        {renderGrowth(dashboardStats?.current_month_pending_revenue, dashboardStats?.last_month_pending_revenue)}%
                                    </label>{t('This Month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="chart-box">
                        <div className="d-flex">
                            <Icon
                                iconName="Userpaid"
                                width="85px"
                                height="84px"
                                mr="15px"
                                mt="15px"
                            />
                            <div>
                                <small>{t('Total Number of Unpaid Users')} / <br/>
                                {t('Total Number of Paid Users')}</small>
                                <h1>{`${dashboardStats?.currentMonthTotalUnPaidUsers || 0}`} / {`${dashboardStats?.currentMonthTotalPaidUsers || 0}`}</h1>
                                <p className="d-flex align-items-center">
                                    <Icon iconSize="tiny" color="#4763E4"
                                          iconName={renderIconName(dashboardStats?.current_month_stats?.count, dashboardStats?.last_month_stats?.count)}/>
                                    <label className={"blue"}>
                                        {renderGrowth(dashboardStats?.current_month_stats?.count, dashboardStats?.last_month_stats?.count || 0)}%
                                    </label>{t('This Month')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlanCard
