import React, { useState, useEffect } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox"
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import Buttons from "../../../../../components/Buttons";
import styled from "styled-components";
import useOutsideClick from "../../../../../customHooks/useOutsideClick";
import Label from "../../../../../components/Label";
import { useTranslation } from "react-i18next";
import FilterIcon from "../../../../../assets/images/Filter-Icon.svg"
import { display, fontWeight, marginRight, paddingBottom } from "styled-system";
import dropdownIcon from "../../../../../assets/images/dropdown-grey.svg";
import info from "../../../../../assets/images/Info.svg";
import { Col } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
const Container = styled(Box)`
  overflow:auto;


  overflow-x:hidden;
  ::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
  }
`;

const IconContainer = styled(Icon)`
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'unset'};
`;

const DropdownItemBox = styled(Box)`
  position: absolute;
  background: rgb(245, 245, 245);
  z-index: 99;
  margin-left: -10px;
  border-radius: ${props => props.isOpen ? '0 0 8px 8px' : '8px'};
`;


const CheckboxMenu = ({ value, options, onChange }) => {
    const [open, setOpen] = useState(false);
    const [openGroups, setOpenGroups] = useState({});
    const [appliedItems, setAppliedItems] = useState([]);
    const {t} = useTranslation();
    const [iconTheme, setIconTheme] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    // console.log("values of the checkboxmenu ",value)
    const valueArray = Array.isArray(value) ? value : Object.values(value).filter(Boolean);
// console.log("valueArray", valueArray);
// console.log("count of items: ", valueArray.length);

    const setInitialValue = () => {
        setOpen(false);
    };

    const ref = useOutsideClick(() => setInitialValue());

    useEffect(() => {
        checkIconFilled();
    }, [selectedItems]);

    useEffect(() => {
      if (Array.isArray(value)) {
        setSelectedItems([...value]);
      } else {
        setSelectedItems([...valueArray]);
      }
    }, [value]);    
    
   const handleDropdownToggle = () =>{
      setOpen(!open)
    }
    const handleApply = () => {
      setOpen(false);
      onChange(selectedItems);
    };
    const handleCancel = () => {
      setAppliedItems([]);
      setSelectedItems([]);
      onChange([]);
      setOpen(false);
    };
    useEffect(() => {
      setAppliedItems(selectedItems);
      // console.log("after setting selectedITems to appliedITems : ",appliedItems)
  }, [selectedItems, appliedItems]);
  
    
    const onChangeSelection = (keyName, keyValue) => {
        let filteredData = [...selectedItems];
        const data = filteredData.filter(item => item[keyName] === keyValue)
        if (data?.length > 0) {
            const filterData = filteredData.filter(item => item[keyName] !== keyValue); // Remove the item from the array
            setSelectedItems(filterData);
        } else {
            let updatedFilters = {};
            updatedFilters[keyName] = keyValue;
            setSelectedItems([...selectedItems,  updatedFilters]); // Add the item to the array
        }
    };

    const checkIconFilled = () => {
        if (selectedItems.length) {
            setIconTheme({ theme: "filled" });
        } else {
            setIconTheme({});
        }
    };

    const getState = (data) => {
        const filteredData = selectedItems.filter(item => item[data?.key_name] === data.value);
        return !!filteredData?.length;
    }

    const handleOpenChange = (e, flag) => {
        setOpen(flag);
        onChange(selectedItems)
    };

    const toggleGroup = (group) => {
      setOpenGroups((prev) => ({
        ...prev,
        [group]: !prev[group],
      }));
    };
    
    const infoPopover = (
      <Popover id="popover-basic" 
      style={{boxShadow: "#0C0C0D0D"}}>
        <Popover.Body style={{ padding: "10px", color: "#1A1A1A", maxWidth: "250px", fontSize: "14px", fontWeight: "500"}}>
          {t("Payment status filter applicable only for current month")}
        </Popover.Body>
      </Popover>
    );
        

    const checkboxRender = () => {
        const groups = Object.keys(options);

        return (
            <DropdownItemBox value={selectedItems} isOpen={open}
            style={{
                backgroundColor:"#FFFFFF",
                border:"1px solid #E7E7E7",
                borderRadius:"10px",
                marginTop:"14px",
                minWidth: "180px"
            }}
            >
              <Container>
                    {groups.map((group, i) => (
                      <Col className={`${i}`} 
                      style={{ borderBottom: i === 2 ? "" : "1px solid #EFF0F6", padding:"10px", paddingBottom: openGroups[group] ? "0px" : "10px"}}>
                        <Box
                          className="d-flex"
                        >
                          <div className="d-flex" onClick={() => toggleGroup(group)} 
                            style={{cursor:"pointer",}}>
                            <img src={dropdownIcon} alt="icon" style={{rotate: openGroups[group] ? "90deg" : ""}}/>
                            <Label 
                              color="#000000"
                              style={{fontWeight: "600", display: "block", fontSize: "15px", marginRight: "10px"}}
                              >
                                {t(group)}
                            </Label>
                          </div>
                          {i === 0 &&
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              style={{ background: "white", padding: 0, border: "1px solid #D9D9D9"}}
                              overlay={infoPopover}
                          >
                            <img src={info} alt="info" style={{ cursor: "pointer" }} />
                          </OverlayTrigger>
                          }
                        </Box>
                        {openGroups[group] && 
                          <div>
                            {options[group].map((item, i) => (
                              <Box 
                                key={i} 
                                display="flex" 
                                alignItems="center" 
                                mb="10px" 
                                style={{fontSize: "14px", fontWeight: "500", paddingLeft: "10px", paddingTop: i === 0 ? "10px" : ""}}
                                className="gap-1">
                                  <Checkbox
                                    isWhite
                                    checked={getState(item)}
                                    value={item.value}
                                    onChange={() => onChangeSelection(item.key_name, item.value)}
                                    style={{ marginRight: "8px" }}
                                  />
                                    {t(item.label)}
                              </Box>
                           ))}
                          </div>
                        }
                      </Col>
                    ))}
              </Container>
              <Box className="d-flex p-2" gridGap="10px">
                <button onClick={handleCancel} className="bg-white"
                 style={{
                  textDecoration: "underline",textDecorationThickness: "1px", border:"unset", color: "#D0004B", borderRadius: "40px", padding: "5px 10px", fontSize: "14px", fontWeight: "700"
                 }}
                 >{t("Clear All")}
                </button>
                <button onClick={handleApply}
                  style={{
                    backgroundColor: "#D0004B", border:"unset", color: "#FFFFFF", borderRadius: "40px", padding: "5px 10px", fontSize: "14px", fontWeight: "700"
                   }}
                   >
                    {t("Apply")}
                </button>
              </Box>
            </DropdownItemBox>
        );
    };

    return (
        <Box position="relative" ref={ref}>
            <Box 
            onClick={handleDropdownToggle} 
            className="flex gap-5" 
            style={{display:"flex",justifyContent:"space-between"}}
            >
                <Label variant="text8" color="#000000"
                style={{fontWeight:"500", display:"block", fontSize:"14px", marginRight:"10px"}}>
                    {t("Filter By")}{" "}
                    {valueArray.length > 0 ? (
                    <span style={{ color: "#7E7E7E", fontWeight: "600" }}>
                      <span style={{color: "#000000"}}>:</span> {valueArray.length} {t("Applied")}
                    </span> ) : 
                    appliedItems.length > 0 ? (
                      <span style={{ color: "#7E7E7E", fontWeight: "600" }}>
                        <span style={{color: "#000000"}}>:</span> {appliedItems.length} {t("Applied")}
                      </span>
                    ) : null }
                </Label> 
                <img src={FilterIcon} alt="icon"/>
            </Box>
            {open && checkboxRender()}
        </Box>
    );
};

export default CheckboxMenu;
