import React, { useEffect, useState } from "react";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DeleteComponent from "../../../components/DeleteComponent";
import Dropdown from "../../../../../components/Dropdown";
import { deletePlan, updatePlan, fetchPlanList } from "../../../mosqueAction";
import { truncate } from "lodash";
import { Input, Select } from "antd";
import Button from "../../../../../components/Buttons/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import {
  getPaymentRecurringOption,
  showNotification,
} from "../../../../../util/Utility";
import NotSavedPage from "./NotSavedPage";
import Label from "../../../../../components/Label";
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

const PlanCards = styled.div`
  position: relative;
  padding: 20px 20px 10px 30px;
  background: #ffffff;
  border: 0.804144px solid #eff0f7;
  box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;
  min-height: 375px;
  .planNo {
    font-weight: 700;
    font-size: 20px;
    color: #1a1a1a;
    
  }

  .planNo span {
    font-weight: 700;
    font-size: 15px;
    color: #1a1a1a;
    text-transform: uppercase;
    
  }

  .Dashborder {
    border-top: 1px dashed #a0aec0;
    margin: 10px 0;
  }
  .DashborderVertical {
    border-right: 1px dashed rgb(160, 174, 192);
    height: 41px;
    width: 1px;
  }

  .price {
    font-weight: 700;
    font-size: 38px;
    color: #1a1a1a;
  }

  .price del {
    font-weight: 600;
    font-size: 18px;
    color: #a0aec0;
    margin-left: 10px;
  }

  .planBtn {
    background: #a0aec0;
    border-radius: 200px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 100%;
    border: none;
    margin: 10px 0;
  }
  .deleteBtn {
    font-weight: 400;
    font-size: 18px;
    color: #fe1717;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }
  .editBtn {
    font-weight: 700;
    font-size: 18px;
    color: #d0004b;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }

  .planDetail {
    font-weight: 600;
    font-size: 18px;
    color: #1a1a1a;
    margin: 15px 0;
  }
  .planDetail span{
    color: #1a1a1a;
  }
  .plan-type{
   color: #7e7e7e;
   font-size: 18px;
   font-weight: 400;
   text-transform: capitalize;
  }
   .plan-type::first-letter{
    text-transform: uppercase
   }
  ul {
    margin-bottom: 0px;
    height: 100px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(26, 26, 26, 0.67);
  }
`;

const EditBoxContainer = styled(Box)`
  display: flex;
  border-radius: 4px;
  border: 1px dashed var(--inactive, #a0aec0);
  background: var(--stroke, #eff0f6);
  width: 100%;
  padding: 2px 8px;
  margin-bottom: 10px;

  input {
    width: 100%;
    border: unset;
    background: #eff0f6;
    font-weight: 700;
  }
`;

const EditSelectContainer = styled(Box)`
  word-wrap: unset;
  .ant-select-selector {
    background: var(--stroke, #eff0f6);
  }
`;

const customMapping = {
  'yearly':'Yearly',
  'monthly':'Monthly',
  'remainingYear':'Remaining months of the year',
  'fullYear':'Full year'
}

const recurringOptions = getPaymentRecurringOption();

const ExistPlan = ({ handleEdit , globalConfig, setShowEdit}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const websiteData = useActiveData();

  const [show, setShow] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [errors, setErrors] = useState({});
  const [details, updateDetails] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [billingOptions, setBillingOptions] = useState([])
  const [chargeOptions, setChargeOptions] = useState([])
  const [id, setId] = useState("");

  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const plans = useSelector(
    (state) => state.mosqueReducer.memberPlans?.planList
  );

  const renderRecurringType = (type) => {
    if (type === "quarterly") {
      return "quarter";
    }
    if (type === "monthly") {
      return "maand";
    }
    return "January";
  };

  const handleDelete = (flag, id) => {
    setShow(true);
    setId(id);
  };

  const optionPaymentDate = () => {
    let option = [];
    for (let i = 1; i <= 20; i++) {
      const data = {};
      data.label = i.toString()
      data.value = i.toString();
      option.push(data);
    }
    return option;
  };

  const handleChangeUser = (keyName, keyValue) => {
    const data = { ...details };
    data[keyName] = keyValue;
    updateDetails(data);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!details.plan_name.trim()) {
      isValid = false;
      showNotification("warning","Please enter plan name");
    }
    if (!details.amount.trim()) {
      isValid = false;
      showNotification("warning","Please enter amount");
    }
    if (!details.recurring_type) {
      isValid = false;
      showNotification("warning", "Please select recurring type");
    }
    if (!details.payment_day) {
      isValid = false;
      showNotification("warning", "Please select payment day");
    }
    if (!details.charge_for) {
      isValid = false;
      showNotification("warning", "Please select charge for");
    }
    return {
      isValid,
      error,
    };
  };

  const handleUpdatePlan = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      const trimmedDetails = {};
      // Trim each attribute and assign it to trimmedDetails object
      for (const key in details) {
        if (typeof details[key] === "string") {
          trimmedDetails[key] = details[key].trim();
        } else {
          trimmedDetails[key] = details[key];
        }
      }

      let updatedValue = {...details};
      updatedValue.payment_day = parseInt(updatedValue?.payment_day);

      dispatch(updatePlan(updatedValue?._id, updatedValue));
    } else {
      setErrors(validate.error);
    }
  };

  const handleCancel = () => {
    setIsCancel(false);
    updateDetails(null);
    dispatch(fetchPlanList(activeEntity?._id));
  };

  const handleEditCancel = () => {
    setIsConfirm(false);
    handleCancel();
  }

  const handleEditClick = (item) => {
    updateDetails(item);
    setIsConfirm(true);
  }

  const handleEditable = (plan) => {
    setIsEditable(true);
    setShowEdit(true)
    setIsConfirm(false);
    handleChangeUser("is_confirm", true);
  }
 console.log(plans);

 function capitalizeFirstLetter(str) {
  if (!str) return ''; // Handle empty or undefined values
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

console.log('update plan', details, globalConfig);

useEffect(()=>{
  if(!globalConfig) return 
  const options = globalConfig?.subscriptions?.map(sub=>
    {
      const temp = {}
      temp.label= sub.name
      temp.value= sub.type
      return temp
    }
  )
  setBillingOptions(options)
  const sub = globalConfig?.subscriptions?.find(sub=> sub.type === "monthly")
      const chargeOp = sub?.options?.map(opt=>{
        const temp = {}
        temp.label= opt.name
        temp.value= opt.type
        return temp
    
      })
      setChargeOptions(chargeOp)
},[globalConfig])

const getContent = ()=>{
  if(isEditable)
  {
    return (
     <div className="add_form subscription-form form_width" style={{height:"400px"}}>
           <div className="mosque-form">
             <div className="row">
               <div className="col-lg-6">
                 <label>
                 {t('Membership Name')}<span style={{ color: "#FE1717" }}>*</span>
                 </label>
                 <input
                   value={details?.plan_name}
                   onChange={(e) => handleChangeUser("plan_name", e.target.value)}
                   type="text"
                   placeholder={t("Membership Name")}
                   className="input-box"
                 />
                 {errors.plan_name && <ErrorMessage>{errors.plan_name}</ErrorMessage>}
               </div>
               <div className="col-lg-6 ">
                 <label>
                 {t('Membership Amount')}<span style={{ color: "#FE1717" }}>*</span>
                 </label>
                 {/* <Input
                   value={planDetails?.amount}
                   // currencySymbols={true}
                   // setCurrencySymbols={(e) => handleChangeUser("currency_symbol", e)}
                   onChange={(e) => handleChangeUser("amount", e.target.value)}
                   type="number"
                   placeholder={t("Bedrag lidmaatschap")}
                   className="input-box"
                 /> */}
                 <CurrencyInput 
                 value={details?.amount}
                 onValueChange={(value) => { 
                   const numericValue = parseFloat(value);
                   if (numericValue === 0 || value === "0.00" || value === "0.0") {
                     return;
                   }
                 handleChangeUser("amount", value)}}
                 decimalSeparator="."
                 decimalsLimit={2}
                 step={10}
                 groupSeparator=","
                 allowNegativeValue={false}
                 placeholder={t("Membership Amount")}
                 className="input-box"
                 />
                 {errors.amount && <ErrorMessage>{t(errors.amount)}</ErrorMessage>}
               </div>
     
               <div className="col-lg-6 pt-4">
                 <label>
                 {t('Billing Frequency')}<span style={{ color: "#FE1717" }}>*</span>
                 </label>
                 <Select
                   size={"middle"}
                   value={details?.recurring_type || t("Monthly")}
                   defaultValue={t("Monthly")}
                   onChange={(value) => handleChangeUser("recurring_type", value)}
                   style={{ width: "100%" }}
                   options={billingOptions}
                 />
                 {errors.recurring_type && <ErrorMessage>{t(errors.recurring_type)}</ErrorMessage>}
               </div>
               <div className="col-lg-6 pt-4">
                 <label>
                 {t('Charge for')}<span style={{ color: "#FE1717" }}>*</span>
                 </label>
                 <Select
                   size={"middle"}
                   value={details?.charge_for || t("select here")}
                   defaultValue={t("select here")}
                   onChange={(value) => handleChangeUser("charge_for", value)}
                   style={{ width: "100%" }}
                   options={chargeOptions}
                 />
                 {errors.charge_for && <div className="mt-3"><ErrorMessage>{t(errors.charge_for)}</ErrorMessage></div>}
               </div>
             </div>
             <Button
              //  type={!isNext ? "disabled" : "primary"}
               text={t("Save Details")}
               width="15%"
               mt="4"
               mx="auto"
               id="btn-margin-top"
               height="60px"
               className='btn-margin-top'
               onClick={() => handleUpdatePlan()}
             />
             <br />
           </div>
         </div>
     
    )
  }else{
    return (
<div >
      <Label className="p-3">{t('To Edit or Delete the existing plan, plz contact support@inhousen.com')}</Label>
      <div className="row p-3">
        {plans?.length === 0 ?
            <div className={"col-md-12"}>
              <br/><br/><br/>
              <div className="text-center">
                {t(websiteData?.PLAN_LIST_PAGE_BLANK_TEXT)}
              </div>
            </div>
          :
            null
        }
        {plans &&
          plans.map((item, index) => (
            <div className="col-md-4" key={index}>
              <PlanCards style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
                <div className="planNo" title={item?.plan_name}>
                <>
                      <Box disply="flex">
                       <span>{truncate(item?.plan_name, { length: 22 })}</span>
                      </Box>
                      {/* <p className="sub-id">{item?._id}</p> */}
                    </>
                </div>
                <div
                  className="d-flex align-items-center price"
                  title={item?.amount}
                >
                 <Box>€ {truncate(item?.amount, { length: 8 })} <span className="plan-type">/ {t(capitalizeFirstLetter(item.recurring_type || 'monthly'))}</span></Box>

                </div>
                <div className="Dashborder"></div>
                <div className="planDetail">
                  <label>{t('Plan Details')}</label>
                  <ul>
                    <li>{t("Billing frequency")} <span>&nbsp; - &nbsp; {t(customMapping[item.recurring_type])}</span></li>
                    <li>{t("Charge for")} <span>&nbsp; - &nbsp; {t(customMapping[item.charge_for]) || t('Remaining months of the year')}</span></li>
                    <li>{t("Payment date")} {item.recurring_type==='monthly'?<span> &nbsp; - &nbsp; {t("1st of every month")}</span>:<span> &nbsp; - &nbsp; {t("1st of next month")}</span>}</li>
                  </ul>
                </div>
                {/* <MarginBox></MarginBox> */}
                <div className="Dashborder"></div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {details?._id === item?._id ? (
                    <button
                      className="deleteBtn"
                      onClick={() => setIsCancel(true)}
                    >
                      <Icon iconName="close" color="red" iconSize="2xs" />
                      <span>{t('Cancel')}</span>
                    </button>
                  ) : (
                    <button
                      className="deleteBtn"
                      onClick={() => handleDelete(true, item?._id)}
                      disabled={!item.isEditable}
                      style={{color:!item.isEditable?'#CCCCCC':''}}
                    >
                      <Icon iconName="delete" color={item?.isEditable?"red":'#ccc'} />
                      <span>{t('Remove')}</span>
                    </button>
                  )}

                  <div className="DashborderVertical"></div>

                  {details?._id === item?._id ? (
                    <button
                      className="editBtn"
                      onClick={() => handleUpdatePlan()}
                    >
                      <Icon iconName="save" />
                      <span>{t('Save')}</span>
                    </button>
                  ) : (
                    <button
                      className="editBtn"
                      onClick={() => handleEditClick(item)}
                      disabled={!item.isEditable}
                      style={{color:!item.isEditable?'#CCCCCC':''}}
                    >
                      <Icon stroke={(item?.isEditable)?"":'#ccc'} color={(item?.isEditable)?"":'#ccc'} iconName="AdminEdit" />
                      <span>{t('Edit')}</span>
                    </button>
                  )}
                </Box>
              </PlanCards>
            </div>
          ))}
      </div>

      <DefaultModal
        show={isConfirm}
        onHide={() => handleEditCancel()}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="d-flex justify-content-between">
          <div></div>
          <div onClick={() => handleEditCancel()}>
            <Icon iconName="blackCross" />
          </div>
        </div>
        <div className="congrats-screen">
          <Box display="flex" justifyContent="center">
            <Icon iconName="notSaved" />
          </Box>
          <Label justifyContent="center" mb="10px" fontSize="18px" fontWeight={700}>{t('Are you sure you want to edit this membership?')}</Label>
          <Label justifyContent="center" fontSize="16px" color="#7E7E7E">
          {/* {t('All linked users will be assigned this new value. Are you sure?')} */}
          </Label>

          <div className="d-flex justify-content-around mt-3">
            <button className="prev-bt" onClick={() => handleEditCancel()}>
            {t('Cancel')}
            </button>
            <button
                className="fill-bt"
                onClick={() => handleEditable()}>
              {t('Edit Membership')}
            </button>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <DeleteComponent
          text={
            t("You will permanently lose the details and payment information of this membership.")
          }
          handleDelete={() => dispatch(deletePlan(id))}
          show={show}
          setshow={setShow}
        />
      </DefaultModal>
      <DefaultModal
        show={isCancel}
        onHide={() => setIsCancel(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <NotSavedPage
          handleEdit={() => handleUpdatePlan()}
          setShow={() => handleCancel()}
        />
      </DefaultModal>
    </div>
    )
  }
}

  return (
    <>
    {getContent()}
    </>
  );
};

export default ExistPlan;
