import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import uploadingGif from '../../../../../components/Icon/Uploading.gif';
import { useTranslation } from "react-i18next";

const FloatingProgressBar = () => {
  const { isUploading, progress } = useSelector((state) => state.uploadReducer);
  const [showProcessing, setShowProcessing] = useState(false);
  const {t} = useTranslation()

  useEffect(() => {
    if (progress === 100) {
      setShowProcessing(true);
      const timer = setTimeout(() => {
        setShowProcessing(false);
      }, 2000); // Show the progress bar for 3 seconds after reaching 100%
      
      return () => clearTimeout(timer);
    }
  }, [progress]);

  if (!isUploading) return null;

  return (
    <div style={styles.container}>
      <div style={styles.infoContainer}>
        <img src={uploadingGif} alt="Uploading..." style={styles.gif} />
        <div>
          <label>{progress < 100 ? t('File uploading...') : t('File Uploaded')}</label>
          <label>{t("This will take a moment")}</label>
        </div>
      </div>

      {/* Progress bar remains visible for 3 seconds after reaching 100% */}
      {progress < 100 || showProcessing ? (
        <div style={{ ...styles.progressBar, width: `${progress}%` }} />
      ) : (
        <div className="text-center">{t("Processing ...")}</div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "250px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    boxShadow: "0px 20.44px 38.6px 0px rgba(26, 19, 107, 0.17)"
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  gif: {
    width: "30px",
    height: "30px",
  },
  progressBar: {
    height: "5px",
    backgroundColor: "rgba(76, 175, 80, 1)",
    transition: "width 0.3s ease-in-out",
    borderRadius: "5px",
    minWidth: '5px'
  },
};

export default FloatingProgressBar;
