import React from 'react'
import {Link} from 'react-router-dom'
import "../../css/header.css";
import moment from "moment";
import useActiveData from "../../customHooks/useActiveData";
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const websiteData = useActiveData();
    const {t} = useTranslation()
    return (
        <div className="footerSection">
            <div className="container">
                <div className='d-deskotop'>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                            <Link href="/">
                                <img src="assets/mosqapp-logo-new.svg" className='logo-footer'/>
                            </Link>
                            <p>{t(websiteData.FOOTER_TAGLINE)}</p>
                            {/*<ul className='footer-icn'>*/}
                            {/*  <li>*/}
                            {/*    <img alt="" src="assets/twit.svg" />*/}
                            {/*  </li>*/}
                            {/*  <li>*/}
                            {/*    <img alt="" src="assets/face.svg" />*/}
                            {/*  </li>*/}
                            {/*  <li>*/}
                            {/*    <img alt="" src="assets/linkdin.svg" />*/}
                            {/*  </li>*/}
                            {/*  <li>*/}
                            {/*    <img alt="" src="assets/instaa.svg" />*/}
                            {/*  </li>*/}
                            {/*</ul>*/}
                            <small>
                                <a>© Mosqapp {`${moment().year()}`}. {t("All right reserved.")}</a>
                            </small>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 padding-top">
                            <h4><span><img src="assets/addr.svg"/></span>{t("Address")}</h4>
                            <h6 dangerouslySetInnerHTML={{__html: websiteData.FOOTER_ADDRESS}}></h6>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 padding-top">
                            <h4><span><img src="assets/call.svg"/></span>{t("Contact Us")}</h4>
                            <h6>
                                <a href={`tel:${websiteData.FOOTER_PHONE}`} style={{color: "#1A1A1A"}}>
                                    {websiteData.FOOTER_PHONE}
                                </a>
                            </h6>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 padding-top">
                            <h4><span><img src="assets/Email.svg"/></span>{t("Email Us")}</h4>
                            <a href={`mailto:${websiteData.FOOTER_EMAIL}`}>{`${websiteData.FOOTER_EMAIL}`}</a>
                        </div>
                    </div>
                </div>
                <div className='d-mobile'>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12">
                            <Link href="/">
                                <img src="assets/mosqapp-logo-new.svg" className='logo-footer'/>
                            </Link>
                            <p>{t(websiteData.FOOTER_TAGLINE)}</p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-12 padding-top">
                            <h4><span><img src="assets/addr.svg"/></span>{t("Address")}</h4>
                            <h6 dangerouslySetInnerHTML={{__html: websiteData.FOOTER_ADDRESS}}>
                            </h6>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 padding-top">
                            <h4><span><img src="assets/call.svg"/></span>{t("Contact Us")}</h4>
                            <h6>
                                <a href={`tel:${websiteData.FOOTER_PHONE}`} style={{color: "#1A1A1A"}}>
                                    {websiteData.FOOTER_PHONE}
                                </a>
                            </h6>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6 padding-top">
                            <h4><span><img src="assets/Email.svg"/></span>{t("Email Us")}</h4>
                            <a href={`mailto:${websiteData.FOOTER_EMAIL}`}>{`${websiteData.FOOTER_EMAIL}`}</a>
                        </div>
                        {/*<ul className='footer-icn'>*/}
                        {/*    <li>*/}
                        {/*      <img alt="" src="assets/twit.svg" />*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <img alt="" src="assets/face.svg" />*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <img alt="" src="assets/linkdin.svg" />*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <img alt="" src="assets/instaa.svg" />*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}
                        <small>
                            <a>© Mosqapp {`${moment().year()}`}. {t("All right reserved.")}</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer