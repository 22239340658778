import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isUploading: false,
  progress: 0,
  uploadedFile: null,
  uploadedFileLink: null,
  isUploadComplete: false,
  refetchUsers: false
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    startUpload: (state) => {
      state.isUploading = true;
      state.progress = 0;
      state.isUploadComplete = false
    },
    updateUploadProgress: (state, action) => {
      state.progress = action.payload;
    },
    uploadComplete: (state, action) => {
      state.isUploading = false;
      state.progress = 100;
      state.uploadedFile = action.payload.uploadedFile;
      state.uploadedFileLink = action.payload.uploadedFileLink;
      state.isUploadComplete = true;
    },
    uploadFailed: (state) => {
      state.isUploading = false;
      state.progress = 0;
      state.isUploadComplete=false
    },
    refetchTempUsers:(state)=>{
      state.refetchUsers = !state.refetchUsers;
    }
  },
});

export const { startUpload, updateUploadProgress, uploadComplete, uploadFailed, refetchTempUsers } = uploadSlice.actions;
export default uploadSlice.reducer;
