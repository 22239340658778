import {React, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
const Banner = ({isPasswordChange}) => {
  const [isChange, setIsChange] = useState(false);
  const handleClick = () => {
    window.location.href = "/";;
  };
  const {t} = useTranslation()
  useEffect(() => {
    setIsChange(isPasswordChange);
  },[isPasswordChange]);
console.log("ischanged value :", isPasswordChange)
  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleClick()} style={{background: "white"}}>
          <img src="/assets/mosqapp-logo-new.svg" style={{height: "70px", width: "auto"}} alt="logo" />
        </div>
        <div className="login_content">
          <h3>{t('MOSQAPP')}</h3>
          <p>
            {!isChange ? t('To change your password, please enter your new password and confirm it before submitting') : t('Password reset status information')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
