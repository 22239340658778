import React, { useEffect, useState } from "react";
import EditPlan from "./EditPlan";
import axios from "axios";
import ExistPlan from "./ExistPlan";
import { showNotification } from "../../../../../util/Utility";
import { useSelector } from "react-redux";

const ExistingPlans = ({ showComponent, setShowComponent, setShowEdit}) => {
  const [editDetails, setEditDetails] = useState(null);
  const [globalConfig, setGlobalConfig] = useState(null)
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  const handleEdit = (flag, details) => {
    setShowComponent(flag);
    setEditDetails(details);
  }

  useEffect(()=>{
    const fetchConfig = async ()=>{
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}entity/client-config/${activeEntity?._id}`)
        setGlobalConfig(res.data)
      } catch (error) {
        console.log(error);
        showNotification('error','error fetching config')
      }
    }
    if(activeEntity?._id)
    fetchConfig()
  },[activeEntity._id])

  return (
    <div>
      {showComponent === "initial" ? (
        <ExistPlan
          handleEdit={handleEdit}
          globalConfig={globalConfig}
          setShowEdit={setShowEdit}
        />
      ) : (
        <EditPlan editDetails={editDetails} />
      )}
    </div>
  );
};

export default ExistingPlans;
