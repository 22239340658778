import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Icon from "../../../../../components/Icon";
import Box from "../../../../../components/Box";
import { useTranslation } from "react-i18next";

const FinancialBoxShadow = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 20px;
`;
const MonthlyDetails = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #7e7e7e;
  margin-bottom: 8px;

  span {
    color: #000000;
  }
`;

const TotalMosque = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1a1a1a;

  span {
    color: #7e7e7e;
    font-weight: 400;
    margin-left: 10px;
  }
`;

const RevenueDetails = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #7e7e7e;
  line-height: 23px;

  span {
    font-weight: 700;
    color: #000000;
  }
`;
const Percent = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
`;
const ProgressBarCustom = styled.div`
  background: #d3edea;
  border-radius: 80px;
  width: 100%;
  height: 10px;
  margin: 25px 0 15px 0;
`;
const ProgressStatus = styled(Box)`
  background: #00ac4f;
  border-radius: 80px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .statusPointer {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: #d0004b;
    border-radius: 50%;
    border: 4px solid #ffffff;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);
  }
`;

const cards = [
  {
    key: "Monthly Users",
    total_key: "total_user_monthly_count",
    current_month_stats: "current_month_monthly_plan_stats",
  },
  {
    key: "Quarterly Users",
    total_key: "total_user_quarterly_count",
    current_month_stats: "current_month_quarterly_plan_stats",
  },
  {
    key: "Yearly Users",
    total_key: "total_user_yearly_count",
    current_month_stats: "current_month_yearly_plan_stats",
  },
];

const UserCard = () => {
  const statsDetails = useSelector(
    (state) => state.mosqueReducer.financialsStats
  );
 const {t} = useTranslation()
  const getTotalOfAllCards = () => {
    let sum = 0;
    cards.forEach((item) => {
      sum += statsDetails[item.total_key.toString()];
    });
    return sum;
  };

  const getCards = ({ total_key, current_month_stats, key }) => {
    return (
      <div className="col-md-4">
        <FinancialBoxShadow>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <MonthlyDetails>
                {key}: <span>{statsDetails[total_key]}</span>
              </MonthlyDetails>
              <RevenueDetails>
                {t('Revenue received')}:{" "}
                <span>
                  {statsDetails &&
                  statsDetails[current_month_stats] &&
                  statsDetails[current_month_stats][0]
                    ? statsDetails[current_month_stats][0].total_amount
                    : 0}
                </span>
              </RevenueDetails>
              <RevenueDetails>
              {t('Income in Progress')}:{" "}
                <span>
                  {statsDetails &&
                  statsDetails[current_month_stats] &&
                  statsDetails[current_month_stats][0]
                    ? statsDetails[current_month_stats][0].total_pending_amount
                    : 0}
                </span>
              </RevenueDetails>
            </div>
            <div>
              <Icon iconName="financialYellow" />
            </div>
          </div>
          <ProgressBarCustom>
            <ProgressStatus
              width={`${
                (statsDetails[total_key] / getTotalOfAllCards()) * 100
              }%`}
            >
              <div className="statusPointer"></div>
            </ProgressStatus>
          </ProgressBarCustom>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <TotalMosque>
              {statsDetails[total_key]}/{getTotalOfAllCards()}
              <span>{t('Total Users')}</span>
            </TotalMosque>
            <Percent>
              {((statsDetails[total_key] / getTotalOfAllCards()) * 100)?.toFixed(0)}%
            </Percent>
          </div>
        </FinancialBoxShadow>
      </div>
    );
  };

  return (
    <div className="user_data">
      <div className="p-0">
        <div className="row">{cards?.map((item) => getCards(item))}</div>
      </div>
    </div>
  );
};

export default UserCard;
