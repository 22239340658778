import en from '../constants/lang/en.json';

export const getWebsiteContentData = (lang) => {
    console.log("he", lang, en);
    if (lang === "en") {
        return en
    }
    return en;
}

export const DataDetails = {
    banner: {
        en: {
            heading: 'Lorem Ipsum English',
            content: '"There is no one who desires pain itself, who seeks it for its own sake, who wants to have it, simply because it is pain...There is no one who loves pain itself, who seeks after it.',
            button: "Join Us"
        },
        nl: {
            heading: 'Lorem Ipsum Netherlands',
            content: '"There is no one who desires pain itself, who seeks it for its own sake, who wants to have it simply because it is pain....There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        de: {
            heading: 'Lorem Ipsum Deutsch',
            content: '"Deutsch Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        es: {
            heading: 'Lorem Ipsum Espanol',
            content: '"There is no one who desires pain itself, who seeks it for its own sake, who wants to have it simply because it is pain....There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        },
        fr: {
            heading: 'Lorem Ipsum Francais',
            content: '"There is no one who desires pain itself, who seeks it for its own sake, who wants to have it simply because it is pain...There is no one who loves pain itself, who seeks after it.',
            button: "Doe met ons mee"
        }
    }
};

export const SliderData = [
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
    {
        "link":"",
        "image":"https://ik.imagekit.io/rzlzf40okya/elixir/slider-1.svg"
    },
]
export const AboutData = [
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Pain itself, it is pain that I love.",
        "paragraph":"But from where all this is born, the error itself, which brings accusatory suffering, I will fully reveal the whole matter."
    },
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Pain itself, it is pain that I love.",
        "paragraph":"But from where all this is born, the error itself, which brings accusatory suffering, I will fully reveal the whole matter."
    },
    {
        "icon":"https://ik.imagekit.io/rzlzf40okya/elixir/freemium_1.svg",
        "title":"Pain itself, it is pain that I love.",
        "paragraph":"But from where all this is born, the error itself, which brings accusatory suffering, I will fully reveal the whole matter."
    },

]
