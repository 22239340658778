import React from "react";
import Icon from "../../../../../components/Icon/Icon";
import Box from "../../../../../components/Box";
import { useTranslation } from "react-i18next";

const NotSavedPage = ({ setShow, handleEdit }) => {
  const {t} = useTranslation()
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div onClick={setShow}>
          <Icon iconName="blackCross" />
        </div>
      </div>
      <div className="congrats-screen">
        <Box display="flex" justifyContent="center">
          <Icon iconName="notSaved" />
        </Box>
        <h4>{t('Changes Not Saved')}</h4>
        <p>
        {t('You have unsaved changes that will be lost if you decide to continue.')}
        </p>

        <div className="d-flex justify-content-around mt-3">
          <button className="prev-bt" onClick={setShow}>
          {t("Don't save")}
          </button>
          <button className="fill-bt" onClick={handleEdit}>
          {t('Save Changes')}
          </button>
        </div>
      </div>
    </>
  );
};

export default NotSavedPage;
