import React, { useCallback, useEffect, useState } from "react";
import Table from "../../../../../components/Table/Table";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Searchbar from "../../../../../components/Searchbar";
import links from "../../../../../constants/customerFilters.json";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import axios from "axios";
import ProfileModal from "./ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCities,
  fetchCustomerInfoList,
  sendMemberReminders,
  setMemberDetailsVisible, fetchSampleExcel,
} from "../../../mosqueAction";
import UploadXls from "./UploadXls";
import Button from "../../../../../components/Buttons";
import Checkbox from "../../../../../components/Checkbox";
import { isMonthUpcoming, showNotification } from "../../../../../util/Utility";
import dayjs from "dayjs";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import CheckboxMenu from "./CheckBoxMenu";
import styled from "styled-components";
import truncate from "lodash/truncate";
import { background, backgroundColor, color, fontSize, height, padding } from "styled-system";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Icon from "../../../../../components/Icon";
import { capitalizeFirstLetter } from "../../../../../util/Utility";
import download from "../../../../../assets/images/download-white-icon.svg";
import Dropdown from "../../../../../components/Dropdown";
import { components } from "react-select";
import dropdownIcon from "../../../../../assets/images/dropdown-primary-icon.svg";
import Select from "react-select"
import { isTime } from "validator";
function MemberTable({
  isFilterBlocked,
  blockedView,
  blockedViewTitle,
  infoPage = true,
}) {
  const { t } = useTranslation();
  const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 220px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;

// const subTypeMap:{
//   'monthly':'Monthly',
//   'yearly':'Yearly'
// }

const TemplateTableData = [
  {
    heading: t("S.No."),
  },
  {
    heading: t("Name"),
  },
  {
    heading: t("Jan"),
  },
  {
    heading: t("Feb"),
  },
  {
    heading: t("Mar"),
  },
  {
    heading: t("Apr"),
  },
  {
    heading: t("May"),
  },
  {
    heading: t("Jun"),
  },
  {
    heading: t("Jul"),
  },
  {
    heading: t("Aug"),
  },
  {
    heading: t("Sep"),
  },
  {
    heading: t("Oct"),
  },
  {
    heading: t("Nov"),
  },
  {
    heading: t("Dec"),
  },
];
  const dispatch = useDispatch();
  const [qtext, setValue] = useState("");
  const [infoModal, setInfoModal] = useState(false)
  const bizIndustryLinks = links;
  const [filters, setFilters] = useState({});
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const isMemberModalVisible = useSelector(
    (state) => state.mosqueReducer.isMemberModalVisible
  );

  const customerList = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoList
  );
  const is_sending_reminder = useSelector(
    (state) => state.mosqueReducer.is_sending_reminder
  );
  const customerInfoCount = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoCount
  );
  const customerPageNum = useSelector(
    (state) => state.mosqueReducer.customerInfo?.pageNum
  );
  const customerPageSize = useSelector(
    (state) => state.mosqueReducer.customerInfo?.pageSize
  );
  const customerInfoPayments = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoPayments
  );
  const isFetching = useSelector(
    (state) => state.mosqueReducer?.customerInfo?.isFetching
  );
  const [selectedUsers, setSelectedUser] = useState({});
  const [isCheckedActive, setIsCheckedActive] = useState(true);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [isMonthly, setIsMonthly] = useState();
  const [isYearly, setIsYearly] = useState();
  const [paymentStatus, setpaymentStatus] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  useEffect(() => {
    if (activeEntity?._id) {
      const filteredDetails = {...filters, isApproved:true}
      dispatch(
        fetchCustomerInfoList({
          entity_id: activeEntity?._id,
          pageSize: customerPageSize,
          pageNum: customerPageNum,
          filters:filteredDetails,
        })
      );
    }
  }, [dispatch, activeEntity, customerPageSize, customerPageNum, filters]);

  function refetchCustomerList() {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: customerPageNum,
        filters: filteredDetails,
      })
    );
  }
  const getStatus = () => {
    if (!!filters?.length) {
      return filters.find((item) => item?.status === "INACTIVE");
    }
  };

  

  useEffect(() => {
    getStatus();
    const newData = { heading: "All" };
    if (!!getStatus() && getStatus()?.status === "INACTIVE") {
      TemplateTableData[0] = newData;
    } else {
      TemplateTableData[0] = { heading: "S.No." };
    }
  }, [filters]);

  const onPageChange = (pageNum) => {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: pageNum,
        filters:filteredDetails,
      })
    );
  };

  const handleFilterChange = ({ pageNum, filters }) => {
    const filteredDetails = {...filters, isApproved:true}
    dispatch(
      fetchCustomerInfoList({
        entity_id: activeEntity?._id,
        pageSize: customerPageSize,
        pageNum: pageNum,
        filters:filteredDetails,
      })
    );
  };
  // const capitalizeFirstLetter = (string) => {
    
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // };

  const renderPaymentStatus = (item, month) => {
    const filteredData = item?.payments?.filter(
      (payment) => payment.month === month
    );
   
    //console.log("Filtered data for month ", month, ":", filteredData);
    if (filteredData?.length > 0) {
      return filteredData.map((payment) => {
        let color = "";
        let tooltipColor = "";
        switch (payment.status) {
          case "FAILED":
          case "MISSED":
            color = "red-color";
            tooltipColor = "#FE1717";
            break;
          case "PENDING":
          case "PROCESSING":
            color = "orange-color";
            tooltipColor = "#eb780d";
            break;
          case "SUCCESS":
            color = "green-color";
            tooltipColor = "#00AC4F";
            break;
        }
        return (
          <div key={month} style={{ display: "flex" }}>
            {filteredData.map((payment) => (
              <Tooltip
                key={payment._id}
                title={
                  <div>
                    <strong>{t("Payment Status")}</strong>
                    <div>
                      <span style={{ color: tooltipColor }}>●</span>{" "}
                      {capitalizeFirstLetter(payment.status)}
                    </div>
                  </div>
                }
              >
                <Box className={color} />
              </Tooltip>
            ))}
          </div>
        );
      });
    } else {
      return <Box ml="7px">{"-"}</Box>;
    }
  };

  const handleChangeFilter = (val) => {
    const { pageNum, filters } = val;
    let updatedFilters = { ...filters };
    updatedFilters[val.key_name] = val.value;
    updatedFilters.value = val.value;
    setFilters(updatedFilters);
    handleFilterChange({
      pageNum: 1,
      filters: updatedFilters,
    });
  };

  const handleSetUser = (id) => {
    let newUser = { ...selectedUsers };
    newUser[id] = !newUser[id] ? 1 : 0;
    setSelectedUser(newUser);
  };

  const showMemberDetails = (item, val = true) => {
    dispatch(setMemberDetailsVisible(item, val));
    if (item?.country) {
      dispatch(fetchCities(item?.country));
    }
  };

  const handleRecurringType = (item ,isMonthly, isYearly) => {
    if(isMonthly && isYearly) {
      return item.activeSubscription?.recurring_type === "monthly" || item.activeSubscription?.recurring_type === "yearly"
    }else if(isYearly) {
      return item.activeSubscription?.recurring_type === "yearly"
    } else if(isMonthly) {
      return item.activeSubscription?.recurring_type === "monthly"
    } else {
      return null;
    }
  }

  const renderMemberList = (isDeactivated, paymentStatus) => {
    if (!customerList) {
      return null; // Or render a loading indicator
    }
    const startingSerialNumber = (customerPageNum - 1) * customerPageSize + 1;
    // console.log("starting is",startingSerialNumber);
    // console.log("customer list without approval---->", customerList);
    return customerList
      .filter((item) => item.isApproved === true &&
      (
        paymentStatus ? (Array.isArray(item.payments) && (item.payments.length > 0 && item.paymentMode !== null )) :
        (Array.isArray(item.payments))
      ) &&
      (isDeactivated === undefined || item.isDeactivated === isDeactivated) &&
      (isMonthly || isYearly ? handleRecurringType(item ,isMonthly, isYearly) : item))
      .map((item, index) => {
        const currentIndex = startingSerialNumber + index; // Calculate current serial number
        const formattedName = (() =>{
          if(!item?.name) {return "NA" }
          const nameParts = item.name.split(","); // Split by comma
          return nameParts.length > 1
                  ? `${nameParts[1].trim()}, ${nameParts[0].trim()}` // lname, fname → fname lname
                  : item.name;
        })();
        const truncattedName = truncate(formattedName, {length: 20});
        return [
          currentIndex,
          <div
            onClick={() => showMemberDetails(item)}
            style={{
              display: "block",
              // color: item?.isDeactivated ? "gray" : "",
            }}
          >
            <h6
              style={{
                cursor: "pointer",
                fontWeight: "600",
                paddingTop: !item?.paymentMode ? "8px" : "",
              }}
              title={item?.name || "NAA"}
              onMouseOver={(e) => (e.target.title = formattedName)}
              onMouseOut={(e) =>
                (e.target.title = item?.name
                  ? truncate(item?.name, { length: 20 })
                  : "NA")
              }
            >
              {truncattedName}{" "}
            </h6>
            <div style={{display:'flex'}}>
              <div>
              {!item?.isMobileOnBoarded && 
                <small
                  style={{
                    color: "#5D4037",
                    background: "#5D40371A",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    marginRight:"8px",
                  }}
                >
                  {t("Offline")}
                </small>
              }
              </div>
              <div>
                {item?.paymentMode === null && (
                  <small
                    style={{
                      color: "#D81B60",
                      background: "#D81B601A",
                      borderRadius: "4px",
                      padding: "0px 4px",
                      marginRight:"8px",
                    }}
                  >
                    {t("Free")}
                  </small>
                )}
              </div>
              <div>
                {item.activeSubscription?.recurring_type && (
                  <small
                    style={{
                      color: item.activeSubscription?.recurring_type === "yearly"
                      ? "#FF5722"
                      : item.activeSubscription?.recurring_type === "monthly"
                      ? "#4763E4"
                      : "",
                      background: item.activeSubscription?.recurring_type === "yearly"
                      ? "#FFEFE9"
                      : item.activeSubscription?.recurring_type === "monthly"
                      ? "#E3F2FD"
                      : "",
                      borderRadius: "4px",
                      padding: "2px 4px",
                      marginRight:"8px",
                    }}
                  >
                    {capitalizeFirstLetter(item.activeSubscription?.recurring_type || '')}
                  </small>
                )}
              </div>
              {item?.paymentMode && (
                <div>
                  <small
                    style={{
                      color: item?.paymentMode === "manual"
                      ? "#673AB7"
                      : item?.paymentMode === "automatic"
                      ? "#00AC4F"
                      : "",
                      background:
                        item?.paymentMode === "manual"
                          ? "#F2E9FE"
                          : item?.paymentMode === "automatic"
                          ? "#E5F7ED"
                          : "",
                      borderRadius: "4px",
                      padding: "2px 4px",
                    }}
                  >
                    {item?.paymentMode === "manual" ? t("Manual") : null}
                    {item?.paymentMode === "automatic"? t("Automatic") : null}
                  </small>
                  {/* <small
                    style={{
                      color: "#00AC4F",
                      background: item?.paymentMethod ? "#E5F7ED" : "",
                      borderRadius: "4px",
                      marginLeft: "8px",
                      padding: "2px 4px",
                    }}
                  >
                    {item?.paymentMethod}
                  </small> */}
                </div>
              )}
            </div>
          </div>,
          renderPaymentStatus(item, 1),
          renderPaymentStatus(item, 2),
          renderPaymentStatus(item, 3),
          renderPaymentStatus(item, 4),
          renderPaymentStatus(item, 5),
          renderPaymentStatus(item, 6),
          renderPaymentStatus(item, 7),
          renderPaymentStatus(item, 8),
          renderPaymentStatus(item, 9),
          renderPaymentStatus(item, 10),
          renderPaymentStatus(item, 11),
          renderPaymentStatus(item, 12),
        ];
      });
  };
  // console.log("Customer List with approved", customerList.filter((item) => item.isApproved === true));
  const getConvertedMemberIds = () => {
    let member_subscriptions = [];
    for (const key in selectedUsers) {
      member_subscriptions.push(key);
    }
    return member_subscriptions;
  };

  const sendReminder = async () => {
    let members = getConvertedMemberIds();
    if (members.length > 0) {
      dispatch(
        sendMemberReminders({
          entity_id: activeEntity?._id,
          members,
        })
      );
    } else {
      showNotification("error","Please Select Users");
    }
  };

  const hasAttributeWithValueOne = Object.values(selectedUsers).some(
    (value) => value === 1
  );

  const handleRedirection = () => {
    window.location.href = `/mosque/${activeEntity?._id}/info`;
  };

  const handleOnClick = () => {
    if (Object.keys(selectedUsers)?.length === customerInfoCount) {
      setSelectedUser({});
    } else {
      setSelectedUser({});
      let newUser = {};
      customerList.map((item) => {
        newUser[item?._id] = !newUser[item?._id] ? 1 : 0;
      });
      setSelectedUser(newUser);
    }
  };

  const isAllAttributesSetToOne = () => {
    if (Object.keys(selectedUsers)?.length === customerInfoCount) {
      return !!Object.keys(selectedUsers).length
        ? Object.values(selectedUsers).every((value) => value === 1)
        : false;
    }
    return false;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (activeEntity?._id) {
        const filteredDetails = {...filters, isApproved:true };
        filteredDetails.qtext = qtext;
        dispatch(
          fetchCustomerInfoList({
            entity_id: activeEntity?._id,
            pageSize: customerPageSize,
            pageNum: 1,
            filters: filteredDetails,
          })
        );
      }
    }
  };

  const handleButton = (val) => {
    if (val === "") {
      if (activeEntity?._id) {
        const filteredDetails = {...filters, isApproved:true};
        dispatch(
          fetchCustomerInfoList({
            entity_id: activeEntity?._id,
            pageSize: customerPageSize,
            pageNum: customerPageNum,
            filters: filteredDetails,
          })
        );
      }
    }
    setValue(val);
  };

  const onCheckboxChange = (selection) => {
    let updatedFilters = {};
    setFilters(selection);
    const payment_statuses_data = selection.filter((item) => item["status"]);
    const payment_mode_data = selection.filter((item) => item["paymentMode"]);
    const recurring_type_data = selection.filter((item) => item["recurring_type"]);
    const member_statuses_data = selection.filter((item) => item["isDeactivated"]);

    console.log("Selection are :", selection);
    if(payment_statuses_data.length > 0) {
      setpaymentStatus(true);
      console.log("Selection of payment status boolean value will be set to: true");
    } else {
      setpaymentStatus(false);
      console.log("Selection of payment status boolean value will be set to: false");
    }
    

    const recurringTypes = recurring_type_data.map((item) => item.recurring_type);
    setIsMonthly(recurringTypes.includes("monthly"));
    setIsYearly(recurringTypes.includes("yearly"));
    // console.log("recurringTypes are :", recurringTypes);
    // console.log("monthly is ",isMonthly);
    // console.log("monthly is ",isYearly);

    if (!!payment_statuses_data?.length) {
      updatedFilters["status"] = payment_statuses_data.map(
        (item) => item.status
      );
    }
    if (!!payment_mode_data?.length) {
      updatedFilters["paymentMode"] = payment_mode_data.map(
        (item) => item.paymentMode
      );
    }
    if (!!recurring_type_data?.length) {
      updatedFilters["recurring_type"] = recurring_type_data.map(
        (item) => item.recurring_type
      );
    }
    if (!!member_statuses_data?.length) {
      updatedFilters["isDeactivated"] = member_statuses_data.map(
        (item) => item.isDeactivated
      );
    }
    handleChangeFilter({ pageNum: 1, filters: selection });
  };
  useEffect(() => {
    console.log("value of paymentStatus is ", paymentStatus)
  }, [paymentStatus]);

  const tableclassName = infoPage
    ? `table-bg member-info-table pb-10`
    : "table-bg mosque-unpaid-table pb-4";
const handleSampleFile = () => {
  dispatch(fetchSampleExcel(activeEntity?._id));
  console.log("getting entity id:",activeEntity?._id)
}
const getStatusOption =  [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  }
];
const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={dropdownIcon} alt="dropdown" width={14} height={14} />
    </components.DropdownIndicator>
  );
};
const handleStatusChange = (selectedOption) => {
  if (selectedOption.value === "active") {
    setIsCheckedActive(true);
    setIsDeactivated(false);
  } else if (selectedOption.value === "inactive") {
    setIsCheckedActive(false);
    setIsDeactivated(true);
  } else {
    setIsCheckedActive(false);
    setIsDeactivated(undefined);
  }
};

const downloadCSV = async () => {
  try {
      const url = process.env.REACT_APP_STAGE === "development"?'https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-ba43fbf1-6938-4aed-86aa-c1464ef7df37/paymentsCsv/csvDownload':'https://faas-ams3-2a2df116.doserverless.co/api/v1/web/fn-7e692bb1-7af6-4f3d-bf31-8955ecc69fc6/paymentsCsvProd/csvDownloadProd'
      setIsSendingEmail(true)
      const response = await fetch(url, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({ entityId: activeEntity?._id }) 
      });

      if (!response.ok) {
          throw new Error("Failed to send  CSV via email");
      }

     showNotification('success','CSV sent to email')
  } catch (error) {
      console.error("Error downloading CSV:", error);
      showNotification('error',"Failed to send  CSV via email")
  }finally{
    setIsSendingEmail(false)
  }
};

useEffect(() => {
  console.log("value of isDeactivated is ", isDeactivated)
}, [isCheckedActive, isDeactivated]);
console.log("member visible is ",isMemberModalVisible )

  return (
    <>
      <div
        className={
          customerList.length >= 5
            ? tableclassName + " member-info-table-height-max"
            : tableclassName + " member-info-table-height-min"
        }
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="20px"
          py="28px"
          className="gap-3"
        >
          <div style={{display:'flex', justifyContent:"space-between", alignItems:'center'}}
          className="gap-3"
          >
          <Label fontSize="20px" fontWeight={700}>
            {blockedView ? blockedViewTitle : t("Member Information")}
          </Label>
          <div className="active-inactive">
            <Label className="block"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius:"10px",
              cursor:"pointer",
              border: "1px solid #E7E7E7",
            }}
            >
              <span 
                className="px-2 pr-0" 
                style={{ color:"#7E7E7E",paddingTop: "8px", paddingBottom: "8px", fontSize: "14px", fontWeight: "400"}}>
                  {t("Status")} :</span>
            <Select
              options={getStatusOption}
              defaultValue={getStatusOption[1]}
              onChange={handleStatusChange}
              components={{
                DropdownIndicator: CustomDropdownIndicator,
                IndicatorSeparator: () => null, // removes the vertical separator line
              }}
              isSearchable={false}
              menuPortalTarget={document.body}
              styles={{
                 control: (base) => ({
                   ...base,
                   border: "none",
                   boxShadow: "none",
                   borderRadius: "10px",
                   cursor: "pointer",
                   fontSize:"14px",
                   fontWeight: isCheckedActive ? 600 : 500,
                   gap:"2rem",
                 }),
                 valueContainer: (base) => ({
                  ...base,
                  padding: 0,
                }),
                 singleValue: (base) => ({
                   ...base,
                   padding:"0",
                   fontWeight: isCheckedActive ? 600 : 500,
                 }),
                 input: (base) => ({
                  ...base,
                  visibility: "hidden",
                  width: 0,
                  height: 0,
                  padding: 0,
                  margin: 0,
                }),
                 option: (base, { isFocused, isSelected }) => ({
                   ...base,
                   backgroundColor: isSelected
                     ? "#f0f0f0"
                     : isFocused
                     ? "#f9f9f9"
                     : "white",
                   color: "#000000",
                   cursor: "pointer",
                   zIndex:"99999",
                   paddingRight:"20px"
                 }),
                 menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
               }}
            />
            </Label>
          </div>
          {/* <UploadXls refetchCustomerList={refetchCustomerList} /> */}
           <div className="d-flex align-items-center gap-2">
           {/* <a href="https://mosqapp.nyc3.digitaloceanspaces.com/sample_mosqapp_member.xlsx" download>
          Sample xls
          </a> */}
          {/* <Tooltip title={
                        <ul>
                          <li>Use this ID {activeEntity?._id} for the Mosque Reference ID column.</li>
                          <li>The Subscription ID can be found under the Membership plan in the Memberships tab.</li>
                          <li><strong>For Free Subscriptions:</strong> Set the Subscription ID and Payment mode to "null" (all lowercase), and leave the Customer IBAN and Customer Account Holder Name fields empty.</li>
                          <li><strong>For Manual Subscriptions:</strong>  Set the Payment mode to "manual" (all lowercase), and leave the Customer IBAN and Customer Account Holder Name fields empty.</li>
                          <li><strong>For Autopay Subscriptions:</strong> Set the Payment mode to "automatic" (all lowercase), and ensure the Customer IBAN and Customer Account Holder Name fields are filled with valid data.</li>
                        </ul>
                      }>
           <Icon iconName="infoRed"/>
          </Tooltip> */}
          {/* <span onClick={() => setInfoModal(true)} style={{ cursor: "pointer" }}>
            <Icon iconName="infoRed" />
           </span> */}
           </div>
          </div>
          <Box className="d-flex justify-center items-center gap-3">
          {!isFilterBlocked ? (
              <BoxContainer 
              style={{ 
                width: "auto" , 
                marginRight: "0px", 
                marginLeft:"0px",
                backgroundColor:"#FFFFFF",
                border: "1px solid #E7E7E7",
                padding: "5px 12px",
                height: "auto"
                }}>
                <CheckboxMenu
                  options={bizIndustryLinks}
                  value={filters}
                  onChange={onCheckboxChange}
                  minheight="280px"
                  height="280px"
                />
              </BoxContainer>
            ) : null}
            <Searchbar
              className="px-4"
              value={qtext}
              setValue={(e) => handleButton(e.target.value)}
              placeholder={t("Search")}
              fontSize="12px"
              border="0px solid rgba(0,0,0,.08)"
              searchIcon
              backgroundColor="#F5F5F5"
              color="#7E7E7E"
              borderRadius="10px"
              paddingLeft="25px"
              position="relative"
              inputProps={{
                type: "search",
                onKeyPress: handleKeyPress,
              }}
            />
            {infoPage &&
            !!getStatus() &&
            getStatus()?.payment_status === "unpaid" ? (
              <Button
                type={hasAttributeWithValueOne ? "primary" : "outlineColor"}
                disabled={is_sending_reminder}
                onClick={() => sendReminder()}
                px="25px"
                minWidth="175px"
                minHeight="35px"
                ml="34px"
              >
                {is_sending_reminder ? t("Sending...") : t("Send Reminder")}
              </Button>
            ) : null}
            <button
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                color: "#FFFFFF",
                backgroundColor: isSendingEmail?"#7e7e7e":"#D0004B",
                borderRadius: "656px",
                padding: "0 12px",
                fontWeight: "700",
                fontSize: "14px",
                border: "none",
                cursor: "pointer"
            }}
            disabled={isSendingEmail}
            onClick={downloadCSV}
        >
              <img src={download} alt="download-icon" />
              {t("Send CSV via Email")}
            </button>
          </Box>
        </Box>
        <Table
          gridTemplateColumnsHeader={
            "8% 30%  5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 0%"
          }
          gridTemplateColumnsBody={
            "8% 30%  5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 0%"
          }
          columns={TemplateTableData}
          data={renderMemberList(isDeactivated, paymentStatus)}
          isAll={isAllAttributesSetToOne()}
          onClickAll={() => handleOnClick()}
          fetching={isFetching}
          height="500px !important"
          noResultText={t("No results found")}
        />
        <DefaultModal
          show={isMemberModalVisible}
          onHide={() => showMemberDetails(null, false)}
          className="profile-modal"
          style={{ paddingRight: "0px", background: "blue" }}
          centered={false}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <ProfileModal
            onHide={() => showMemberDetails(null, false)}
            refetchCustomerList={refetchCustomerList}
          />
        </DefaultModal>
        
            
      </div>
      <PaginationV2
        currentPage={customerPageNum}
        pageSize={customerPageSize}
        totalCount={customerInfoCount}
        onPageChange={onPageChange}
      />
      <Modal
        title={t("Instructions for Uploading")}
        open={infoModal}
        onCancel={() => setInfoModal(false)}
        footer={null}
      >
        <ul>
          <li>{t("Fields marked with an asterisk")} <strong>(*)</strong> {t("are mandatory and must be updated for all user data.")}</li>
          <li><strong>{t("For Free Subscriptions:")}</strong> {t("Payment mode should be selected as “none” and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Manual Subscriptions:")}</strong> {t("Payment mode should be selected as “manual” and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Autopay Subscriptions:")}</strong> {t("Payment mode should be selected as “automatic” and ensure the Customer IBAN and Customer Account Holder Name fields are filled with valid data.")}</li>
        </ul>
      </Modal>
    </>
  );
}

export default MemberTable;
