import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./BulkUploadMember.css";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/Icon";
import uploadImg from "./components/cloud_upload.svg";
import UploadXls from "./components/UploadXls";
import { useSelector } from "react-redux";
import { showNotification } from "../../../../util/Utility";
import axios from "axios";
import Loader2 from "../../../../components/Loader2";
import Table from "../../../../components/Table/Table";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import ReUploadXls from "./components/ReUploadXls";
import discImg from "./disclaimer.svg";
import DownloadErrorExcel from "./components/DownloadErrorExcel";
import { refetchTempUsers } from "../../uploadProgressReducer";
import { useDispatch } from "react-redux";
import PaginationV2 from "../../../../components/Pagination/PaginationV2"

import { Tooltip, Modal } from "antd";

const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

function BulkUploadMembers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const refetchUsers = useSelector((state) => state.uploadReducer.refetchUsers);
  const [successUsers, setSuccessUsers] = useState([]);
  const [errorUsers, setErrorUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessUsers, setShowSuccessUsers] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const [totalPagesSuccess, setTotalPagesSuccess] = useState(1);
const [currentPageSuccess, setCurrentPageSuccess] = useState(1);
const [currentPageError, setCurrentPageError] = useState(1);
const [pageSize] = useState(30);
const [totalCountSuccess, setTotalCountSuccess] = useState(0);
const [totalCountError, setTotalCountError] = useState(0);
  const [templateTableData1, setTemplateTableData1] = useState([
    {
      heading: "S.No.",
    },
    {
      heading: t("Name"),
    },
    {
      heading: t("Email Address"),
    },
    {
      heading: t("Contact No."),
    },
    {
      heading: t("Address"),
    },
  ]);
  const [templateTableData2, setTemplateTableData2] = useState([
    {
      heading: "S.No.",
    },
    {
      heading: t("Name"),
    },
    {
      heading: t("Email Address"),
    },
    {
      heading: t("Contact No."),
    },
    {
      heading: t("Address"),
    },
    {
      heading: t("Errors"),
    },
  ]);

  const handlePageChangeSuccess = (page) => {
    setCurrentPageSuccess(page);
  };
  const handlePageChangeError = (page) => {
    setCurrentPageError(page);
  };
  // useEffect(() => {
  //   const getTemporaryUsers = async () => {
  //     try {
  //       setIsLoading(true);
  //       const resp = await axios.get(
  //         `${process.env.REACT_APP_API_URL}agent/dashboard/fetch-uploaded-users?entityId=${activeEntity?._id}`
  //       );
  //       if (resp.status === 200) {
  //         setErrorUsers(resp.data?.errorUsers);
  //         setSuccessUsers(resp.data?.successUsers);
  //       }
  //     } catch (error) {
  //       console.log("Temp users:", error);
  //       showNotification("error", "error fetching temporary users");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (activeEntity._id) getTexporaryUsers();
  // }, [activeEntity?._id, refetchUsers]);

  const getSuccessUsers = async (page) => {
    try {
      setIsLoading(true)
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}agent/dashboard/fetch-success-users?entityId=${activeEntity?._id}&page=${page}&pageSize=${pageSize}`
      );
  
      if (resp.status === 200) {
        setSuccessUsers(resp.data.users);
        setTotalCountSuccess(resp.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching success users:", error);
      showNotification('error', error)
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getSuccessUsers(currentPageSuccess);
  }, [currentPageSuccess, activeEntity._id, refetchUsers]);


  const getErrorUsers = async (page) => {
    try {
      setIsLoading(true)
      const resp = await axios.get(
        `${process.env.REACT_APP_API_URL}agent/dashboard/fetch-error-users?entityId=${activeEntity?._id}&page=${page}&pageSize=${pageSize}`
      );
  
      if (resp.status === 200) {
        setErrorUsers(resp.data.users);
        setTotalCountError(resp.data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching error users:", error);
      showNotification('error',error)
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getErrorUsers(currentPageError);
  }, [currentPageError, activeEntity._id, refetchUsers]);

  const getPillClass = (paymentMode) => {
    if (paymentMode === "automatic") return "pm-pill automatic-pill";
    else if (paymentMode === "manual") return "pm-pill manual-pill";
    else return "pm-pill free-pill";
  };

  const translateError = (errorString) => {
    if (!errorString) return "-";
  
    return errorString
      .split(" , ")
      .map((error) => t(error))
      .join(", ");
  };

  const renderMemberList = () => {
    const data = showSuccessUsers
      ? successUsers.map((user, i) => [
          <label>{(currentPageSuccess - 1) * pageSize + (i + 1)}</label>,
          <div>
            {user?.name ? user.name.split(", ").reverse().join(", ") : "-"}
            <div className={getPillClass(user?.paymentMode)}>
              {user?.paymentMode || "Free Subscription"}
            </div>
          </div>,
          <label>{user?.emailAddress || "-"}</label>,
          <label>{user?.phoneNumber || "-"}</label>,
          <label>{user?.address || "-"}</label>,
        ])
      : errorUsers.map((user, i) => [
          <label>{(currentPageError - 1) * pageSize + (i + 1)}</label>,
          <div>
            {user?.name ? user.name.split(", ").reverse().join(", ") : "-"}
            <div className={getPillClass(user?.paymentMode)}>
              {user?.paymentMode || "Free"}
            </div>
          </div>,
          <label>{user?.emailAddress || "-"}</label>,
          <label>{user?.phoneNumber || "-"}</label>,
          <label>{user?.address || "-"}</label>,
          <label>
            <Tooltip title={translateError(user?.userErrors)}>
              <Icon iconName="infoRed" />
            </Tooltip>
          </label>,
        ]);
    return data;
  };

  const handleSaveUsers = async () => {
    try {
      setIsProceeding(true);
      await axios.get(
        `${process.env.REACT_APP_API_URL}agent/dashboard/save-users?entityId=${activeEntity?._id}`
      );
      showNotification("success", "users saved successfully");
      window.location.href = `/mosque/${activeEntity?._id}/info`;
    } catch (error) {
      console.log(error);
      showNotification("error", "error saving users..");
    } finally {
      dispatch(refetchTempUsers());
      setShowProgressModal(false);
      setIsProceeding(false);
    }
  };

  const handleSampleDownload = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}agent/entity/${activeEntity?._id}/sample-excel`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      // Extract base64 string and file name from the response
      const { file, fileName } = res.data;

      if (!file) throw new Error("No file data received");

      // Convert base64 string to a Blob
      const byteCharacters = atob(file); // Decode Base64
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "sample.xlsx"; // Use provided file name or default
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download error:", error);
      showNotification("error", "Error downloading sample");
    }
  };

  const getContent = () => {
    if (errorUsers.length > 0 || successUsers.length > 0) {
      return (
        <div>
          <Table
            gridTemplateColumnsHeader={"8% 20% 26% 27% 12% 7%"}
            gridTemplateColumnsBody={"8% 20% 26% 27% 12% 7%"}
            columns={showSuccessUsers ? templateTableData1 : templateTableData2}
            data={renderMemberList()}
            noTableClass = {true}
            // height="500px !important"
          />
        </div>
      );
    } else {
      return (
        <div style={{ padding: "40px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="headingTwo">{t("Bulk Upload")}</h2>
            <div className="sample-file-wrapper">
              <span
                onClick={() => setInfoModal(true)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="redInfoIcon" />
              </span>
              <label role="button" className="sample-file" onClick={handleSampleDownload}>
                {t("Sample file")}
              </label>
            </div>
          </div>
          <label style={{ color: "#7e7e7e" }}>
            {t("Upload csv file to add multiple members")}{" "}
          </label>
          <div className="upload-container-inner">
            <img
              src={uploadImg}
              alt="cloud"
              height="150px"
              width="150px"
              style={{ marginBottom: "30px" }}
            />
            <label>{t("Choose a file")} </label>
            <UploadXls />
          </div>
        </div>
      );
    }
  };

  const handleIgnore = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}agent/dashboard/ignore-users?entityId=${activeEntity?._id}`
      );
      dispatch(refetchTempUsers());
    } catch (error) {
      console.log(error);
      showNotification("error", "some error occurred...");
    }
  };

  return (
    <div className="grid-sec">
      <RightSide open={true}>
        {(errorUsers.length > 0 || successUsers.length > 0) && (
          <>
            <div
              className="sample-file-wrapper"
              style={{ marginBottom: "30px" }}
            >
              <span
                onClick={() => setInfoModal(true)}
                style={{ cursor: "pointer" }}
              >
                <Icon iconName="redInfoIcon" />
              </span>
              <label role="button" className="sample-file-2" onClick={handleSampleDownload}>
              {t("Sample file")}
              </label>
            </div>
            <div className="d-flex justify-content-between ">
              <div className="left-tabs">
                <div
                  role="button"
                  onClick={() => setShowSuccessUsers(true)}
                  className={showSuccessUsers ? "active-tab" : "inactive"}
                >
                  {t("Ready to Save")} ({totalCountSuccess})
                </div>
                <div
                  role="button"
                  onClick={() => setShowSuccessUsers(false)}
                  className={!showSuccessUsers ? "active-tab" : "inactive"}
                >
                  {t("Errors")} ({totalCountError})
                </div>
              </div>
              <div className="right-btns ">
                {showSuccessUsers ? (
                  <div className="d-flex justify-content-end gap-1">
                    <button
                      onClick={handleIgnore}
                      className="proceed-btn proceed-btn-outlined"
                    >
                      {t("Ignore")}
                    </button>
                    <button
                      onClick={() => {
                        errorUsers.length > 0
                          ? setShowProgressModal(true)
                          : handleSaveUsers();
                      }}
                      disabled={isProceeding}
                      className="proceed-btn"
                    >
                      {isProceeding ? t("Saving users ...") : t("Save Users")}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end gap-1">
                    <button
                      onClick={() => {
                        errorUsers.length > 0
                          ? setShowProgressModal(true)
                          : handleSaveUsers();
                      }}
                      disabled={isProceeding}
                      className="proceed-btn proceed-btn-outlined"
                    >
                      {isProceeding ? t("Saving users ...") : t("Save Users")}
                    </button>
                    <ReUploadXls setCurrentPageSuccess={setCurrentPageSuccess} setCurrentPageError={setCurrentPageError} errorUsers={errorUsers} />
                    <DownloadErrorExcel errorUsers={errorUsers} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {!showSuccessUsers &&
          (errorUsers.length > 0 || successUsers.length > 0) && (
            <div className="fix-errors-text">
              <Icon iconName="greyInfoIcon" />
              <strong>{t("To fix errors,")}</strong> {t(`download the error file, make changes, and upload it again.`)}
            </div>
          )}
        <div
          className="upload-container"
          style={{
            borderTopLeftRadius:
              errorUsers.length > 0 || successUsers.length > 0 ? "0" : "",
            borderTopRightRadius:
              errorUsers.length > 0 || successUsers.length > 0 ? "0" : "",
          }}
        >
          {isLoading ? (
            <Loader2 />
          ) : (
            <div className="margin-sec">{getContent()}</div>
          )}
        </div>
        {showSuccessUsers ? (
            <PaginationV2
            currentPage={currentPageSuccess}
            pageSize={pageSize}
            totalCount={totalCountSuccess}
            onPageChange={handlePageChangeSuccess}
          />
          ) : (
            <PaginationV2
              currentPage={currentPageError}
              pageSize={pageSize}
              totalCount={totalCountError}
              onPageChange={handlePageChangeError}
            />
          )}
      </RightSide>
      <DefaultModal
        onHide={() => setShowProgressModal(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        show={showProgressModal}
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="proceed-dialog">
          <div
            className="black-cross"
            onClick={() => setShowProgressModal(false)}
          >
            <Icon iconName="blackCross" />
          </div>
          <img src={discImg} alt="disclaimer" />
          <h4>{t("Review & Fix Member Details")}</h4>
          <p className="text-center">
            {t(
              "Some members have missing or incorrect details. Review and update their information to ensure a smooth workflow."
            )}
          </p>

          <div className="d-flex gap-5">
            <button
              className="proceed-btn proceed-btn-outlined"
              style={{ marginRight: "0" }}
              onClick={handleSaveUsers}
            >
              {isProceeding ? t("Saving users ..."):t("Continue Anyway")}
            </button>
            <button
              onClick={() => setShowProgressModal(false)}
              className="proceed-btn"
            >
              {t("Fix Now")}
            </button>
          </div>
        </div>
      </DefaultModal>
      <Modal
        title={t("Instructions for Uploading")}
        open={infoModal}
        onCancel={() => setInfoModal(false)}
        footer={null}
      >
        <ul>
          <li>{t("Fields marked with an asterisk")} <strong>(*)</strong> {t("are mandatory and must be updated for all user data.")}</li>
          <li><strong>{t("For Free Subscriptions:")}</strong> {t("Payment mode should be selected as “none” and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Manual Subscriptions:")}</strong> {t("Payment mode should be selected as “manual” and leave the Customer IBAN and Customer Account Holder Name fields empty.")}</li>
          <li><strong>{t("For Autopay Subscriptions:")}</strong> {t("Payment mode should be selected as “automatic” and ensure the Customer IBAN and Customer Account Holder Name fields are filled with valid data.")}</li>
        </ul>
      </Modal>
    </div>
  );
}

export default BulkUploadMembers;
