import React, { useState, useRef, useEffect } from 'react';
import './CountrySelect.css'; // Import the CSS file
import Icon from "../Icon/Icon";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { STORE_CITIES, FETCHING_CITIES } from '../../modules/Mosque/mosqueActionType';
import { Spinner } from 'react-bootstrap';
import { opacity } from 'styled-system';
import { useTranslation } from 'react-i18next';

const CountrySelect = ({ defaultText, handleChangeUser, onboardingDetails, cities }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const debounceTimeout = useRef(null)
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchQuery(''); // Reset search query when toggling dropdown
  };

  const handleCountrySelect = (city) => {
    setSelectedCountry(city);
    handleChangeUser("entity_city", city);
    setIsOpen(false);
    setSearchQuery(''); // Reset search query on selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchQuery(''); // Reset search query on outside click
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const filteredCities = cities?.filter(city =>
  //   city.label.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  useEffect(() => {
    if (!searchQuery.length  || !onboardingDetails.entity_country) return;
    if(searchQuery.length<3){
      setIsLoading(false)
      dispatch({
        type: STORE_CITIES,
        cities: [],
      });
      return
    } 

    // Clear the previous timeout if user types before 2s
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    setIsLoading(true);
    debounceTimeout.current = setTimeout(async () => {
      try {
        // console.log('Query:', searchQuery, onboardingDetails.entity_country);
        const country = onboardingDetails.entity_country;
        const city = searchQuery;
        const baseUrl =
        process.env.REACT_APP_STAGE === 'development'
          ? 'apidev.mosqapp.com'
          : 'api.mosqapp.com';
        const response = await axios.get(
          `https://${baseUrl}/mapbox/fetch-cities?country=${country}&city=${city}`
        );
        
        // console.log(response.data?.features[0]);
        const cities = response.data?.cities;
        
        if (response.status === 200) {
          dispatch({
            type: STORE_CITIES,
            cities: cities.map((city) => ({ label: city, value: city })),
          });
        }
      } catch (error) {
        console.log('Mapbox error: ', error);
        toast.error('Some error occurred');
      }finally{
        setIsLoading(false)
      }
    }, 1500); // 1.5 seconds debounce delay

    return () => clearTimeout(debounceTimeout.current); // Cleanup on unmount or before next execution
  }, [searchQuery, onboardingDetails.entity_country]);

  return (
    <div className="dropdown-container" style={{pointerEvents:onboardingDetails.entity_country?'':'none', opacity:onboardingDetails.entity_country?1:0.5}} ref={dropdownRef}>
      <div className="input-box dropdown-header" onClick={toggleDropdown}>
        {isOpen ? (
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            // placeholder="Type atleast 3 characters ..."
            className="dropdown-search"
            autoFocus 
          />
        ) : (
          <span>{onboardingDetails?.entity_city || defaultText}</span>
        )}
        <span className="arrow">
          {isOpen ? (
            <Icon iconName="arrowUp" arrowSize="tiny" />
          ) : (
            <Icon iconName="arrowDown" arrowSize="tiny" />
          )}
        </span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {
            isLoading ? <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: "100%" }}
                          >
                            <Spinner />
                          </div>:cities?.map((city, i) => (
              <li 
                key={i} 
                onClick={() => handleCountrySelect(city.value)}
                className="dropdown-list-item"
              >
                {city.label}
              </li>
            ))}
        </ul>
      )}
      { isOpen && searchQuery?.length <3 && <p className='text-danger'>{t("Please type at least 3 characters to search for cities.")}</p>}
    </div>
  );
};

export default CountrySelect;
