import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function Canvas({ setIsCanvasDrawn, setCanvasError, onSignatureDrawn }) {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [drawingPath, setDrawingPath] = useState([]);

  const handleStart = (x, y) => {
    setIsDrawing(true);
    setLastX(x);
    setLastY(y);
    setDrawingPath([]);
    setCanvasError(false); // Reset error state on new drawing
  };

  const handleMove = (x, y) => {
    if (!isDrawing) return;

    const path = [lastX, lastY, x, y];
    setDrawingPath((prevPath) => [...prevPath, path]);

    draw();
    setLastX(x);
    setLastY(y);
  };

  const handleMouseDown = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    handleStart(x, y);
  };

  const handleMouseMove = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    handleMove(x, y);
  };

  const handleTouchStart = (event) => {
    event.preventDefault(); // Prevent scrolling
    const touch = event.touches[0];
    const rect = canvasRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    handleStart(x, y);
  };

  const handleTouchMove = (event) => {
    event.preventDefault(); // Prevent scrolling while drawing
    const touch = event.touches[0];
    const rect = canvasRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    handleMove(x, y);
  };

  const handleTouchEnd = (event) => {
    event.preventDefault(); // Prevent scrolling
    handleStop();
  };

  const handleStop = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const signatureDataURL = canvas.toDataURL(); // Convert canvas to data URL

    // Check if there's something drawn before calling onSignatureDrawn
    if (drawingPath.length > 2) {
      setIsCanvasDrawn(true);
      onSignatureDrawn(signatureDataURL); // Pass signature data to parent component
    } 
    // else {
    //   // Set error state
    //   setCanvasErrorState(true); // Indicate an error
    // }
  };

  const draw = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    drawingPath.forEach((path) => {
      context.beginPath();
      context.moveTo(path[0], path[1]);
      context.lineTo(path[2], path[3]);
      context.strokeStyle = '#000'; // color
      context.lineWidth = 5; // width
      context.lineCap = 'round'; // style
      context.stroke();
    });
  };

  const handleResetCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    setIsCanvasDrawn(false);
    setDrawingPath([]);
    onSignatureDrawn(null);
    setCanvasError(false); // Reset error state
  };

  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <canvas
        ref={canvasRef}
        height={200}
        width={window.innerWidth > 1000 ? 570 : 300}
        style={{
          border: '1px solid #EFF0F7',
          boxShadow: '0px 2px 6px #13124212',
          borderRadius: '8px',
          touchAction: 'none', // Disable touch actions like scrolling
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleStop}
        onMouseOut={handleStop}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />
      {/* {canvasError && <div style={{ color: 'red' }}>Please draw your signature before submitting.</div>} */}
      <button
        type="button"
        onClick={handleResetCanvas}
        style={{
          position: 'absolute',
          bottom: 20,
          right: 40,
          height: 20,
          width: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          background: 'inherit',
          color: '#D0004B',
          fontWeight: 600,
          fontSize: '18px',
        }}
      >
        {t('Clear')}
      </button>
    </div>
  );
}

export default Canvas;
