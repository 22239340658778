import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import truncate from "lodash/truncate";
import Button from "../../../../../components/Buttons/Button";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import { fetchAgentBlogList } from "../../../mosqueAction";
import Box from "../../../../../components/Box";
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from "react-i18next";
import Icon from "../../../../../components/Icon/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DeleteComponent from "../../../components/DeleteComponent";
import { updateBlog } from "../../../mosqueAction";

moment.updateLocale("nl", {
  months: [
    "Jan",
    "Feb",
    "Mrt",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ],
});

const BlogCard = () => {
  const websiteData = useActiveData();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const blogList = useSelector((state) => state.mosqueReducer.blog_list);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const blogCount = useSelector(
    (state) => state.mosqueReducer.total_blog_count
  );
  const currentPage = useSelector((state) => state.mosqueReducer.blog_page_num);
  const pageSize = useSelector((state) => state.mosqueReducer.blog_page_size);
  const [menuVisible, setMenuVisible] = useState(null)
  const onPageChange = (page) => {
    if (activeEntity?._id) {
      dispatch(
        fetchAgentBlogList({
          entity_id: activeEntity._id,
          pageNum: page,
          pageSize: pageSize,
        })
      );
    }
  };

  const toggleMenu = (itemId, e) => {
    e.preventDefault();
    e.stopPropagation();

    // Toggle the menu visibility for the clicked blog card
    if (menuVisible === itemId) {
      setMenuVisible(null); // Close if the same menu is clicked
    } else {
      setMenuVisible(itemId); // Open the menu for the clicked blog card
    }
  };

  const handleEdit = (e,itemId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/mosque/${activeEntity._id}/blogs/${itemId}/edit`);
  };

  const handleRemoveBlog2 = (blog_id) => {
    const blog = {};
    blog.is_deleted = true;
    dispatch(updateBlog(blog_id, blog, true))
    navigate(`/mosque/${activeEntity?._id}/blogs`);
}

  const handleDelete = (e,itemId) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setSelectedId(itemId);
  };

  return (
    <div className="container">
      <div className="row">
        {blogList?.length === 0 ? (
          <div className={"col-md-12"}>
            <br />
            <br />
            <br />
            <div className="text-center">
              {t(websiteData?.BLOG_LIST_PAGE_BLANK_TEXT)}
            </div>
          </div>
        ) : null}
        {blogList &&
          blogList.map((item) => (
            <div className="col-lg-4 mb-4 mt-4">
              <Link
                to={`/mosque/${item?._id}/blogs/${item?.url}/info`}
                style={{ textDecoration: "none" }}
              >
                <div className="blog-card">
                  <div className="blog-card-header">
                    <img src={item?.preview_image} alt="preview images" />
                    <div className="blog-card-header-content">
                      <h4>
                        {truncate(item?.heading.toUpperCase(), { length: 75 })}
                      </h4>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <p>
                          {t("By")}{" "}
                          {truncate(item?.author_name, { length: 10 })}
                        </p>
                        <span
                          className="dot"
                          style={{ background: "rgba(226, 226, 226, 1)" }}
                        ></span>
                        <p>
                          {(new Date(item?.published_date)<=new Date())?moment(new Date(item?.published_date)).format(
                            "MMM DD, YYYY"
                          ):t('Publishing Pending')}
                        </p>
                      </div>
                    </div>
                    
                  </div>
                  <p className="blog-card-text">
                    {truncate(item?.text, { length: 100 })}
                  </p>
                  <div className="blog-card-menu" onClick={(e) => e.stopPropagation()}>
                  <Icon
                    iconName="menuIcon"
                    onClick={(e) => toggleMenu(item?._id, e)}
                  />
                  {menuVisible === item?._id && (
                    <div className="menu-list">
                      <ul>
                        <li onClick={(e) => handleEdit(e,item?._id)}>{t('Edit Details')}</li>
                        <li onClick={(e) => handleDelete(e,item?._id)}>{t('Delete')}</li>
                      </ul>
                    </div>
                  )}
                </div>
                </div>
              </Link>
              
            </div>
          ))}
      </div>
      <PaginationV2
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={blogCount}
        onPageChange={onPageChange}
      />
      <DefaultModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                className="congrats-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <DeleteComponent
                    text={t("You will permanently lose the details of this news item and other information.")}
                    handleDelete={() => handleRemoveBlog2(selectedId)}
                    show={showDeleteModal} setshow={setShowDeleteModal} />
            </DefaultModal>
    </div>
  );
};

export default BlogCard;
