import React from "react";
import { useTranslation } from "react-i18next";
function LoginBanner() {
    const {t} = useTranslation()
    const handleLogoClick = () => {
        window.location.href = "/";
    }
    return (
        <div className="login_banner">
            <div className="logo_im_mobile" style={{background: "white"}}>
            {/*<div className="logo-div" onClick={() => handleLogoClick()}>*/}
                <img style={{ height: "70px", width: "auto" }} src="./assets/mosqapp-logo-new.svg"/>
            </div>
            <div className="container">
                <div className="logo_im mobile-view" style={{background: "white"}} onClick={() => handleLogoClick()}>
                    {/*<div className="logo-div" onClick={() => handleLogoClick()}>*/}
                        <img style={{ height: "70px", width: "auto" }} src="assets/mosqapp-logo-new.svg"/>
                    </div>
                {/*</div>*/}
                <div className="login_content">
                    <h3>{t('Welcome!!')}</h3>
                    <p>
                    {t('Log in or create a new account')}
                    </p>
                    <br/>
                </div>
                <br/>
            </div>
        </div>
    );
}

export default LoginBanner;
