import React, { useState, useEffect } from "react";
import SideBarLinks from "../../../constants/SideBarLinksMosque.json";
import Icon from "../../../components/Icon/Icon";
import { Link, NavLink, useLocation } from "react-router-dom";
import Button from "../../../components/Buttons/Button";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Box from "../../../components/Box";
import { useTranslation } from "react-i18next";
import FundMeButton from "../pages/Donate/components/FundMeButton";
import { background, borderRadius, fontSize } from "styled-system";


const styles = {
  dropdownContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  dropdownButton: {
    background: "inherit",
    border: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#D0004B",
    paddingLeft:'10px'
  },
  arrow: (isOpen) => ({
    marginLeft: "10px",
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  }),
  dropdownMenu: {
    // position: "absolute",
    // top: "100%",
    left: "0",
    background: "inherit",
    // width: "200px",
    display: "flex",
    flexDirection: "column",
    paddingLeft:'25px',
    // padding: "10px",
    fontSize:'14px'
  },
  dropdownItem: {
    textAlign: "left",
    cursor: "pointer",
    textDecoration: "none",
    color: "black",
  },
  activeItem:{
  background:'#d0004b',
  textAlign: "left",
    cursor: "pointer",
    textDecoration: "none",
    color: "white",
    borderRadius:'15px'
  },
  dropdownItemHover: {
    backgroundColor: "#f5f5f5",
  },
  bulkUpload: {
    color: "black",
  },
  // bulkUploadHover: {
  //   backgroundColor: "#B0003A",
  // },
};

const LeftSidebar = ({ open, setOpen }) => {
  const location = useLocation();
  const [show, setshow] = useState(false);
  const [linkRefs, setlinRefs] = useState(SideBarLinks);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {t} = useTranslation()

  const user = useSelector((state) => state.customerReducer.user);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  useEffect(() => {
    const link = location?.pathname;
    const index = SideBarLinks.findIndex(item => `/mosque/${activeEntity?._id}/${item.link}` === link);
    if (location?.pathname === `/mosque/${activeEntity?._id}/dashboard`) {
      active(0);
    }
    if (location?.pathname === `/login`) {
      active(0);
    }
    if (index >= 0) {
      active(index);
    }
  }, [activeEntity])

  useEffect(() => {
    if (location?.pathname === `/mosque/${activeEntity?._id}/members/add`) {
      let newLinkRefs = SideBarLinks.map((item) => {
        item.active = item?.link === "members/add";
        return item;
      });
      setlinRefs(newLinkRefs);
    }
  }, [location]);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const active = (i) => {
    
    let newLinkRefs = SideBarLinks.map((link) => {
      link.active = false;
      return link;
    });
    if(i=== -1) return
    SideBarLinks[i].active = true;
    setDropdownOpen(false)
    setlinRefs(newLinkRefs);
  };

  useEffect(()=>{
    if(dropdownOpen){
      active(-1)
    }
  },[dropdownOpen])

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    window.location = "/login";
  };

  const handleBulkUpload = () => {

  };

  return (
    <div

      // className="sidebar_bg"
      className={open ? "whiteBg" : "blackBg"}
      // style={{
      //   background: open ? "white" : "#1A1A1A",
      //   width: open ? "20%" : "calc(25% - 15%)",
      // }}

    >
      <div
        // className="logo-im"
        className={open ? "wLogo" : "bLogo"}
        style={{paddingLeft: "0px"}}
        // style={{
        //   color: open ? "#D0004B" : "white",
        //   padding: open ? "20px 35px" : "20px",
        //   textAlign: open ? "left" : "left",
        //   display: open ? "block" : "flex",
        //   justifyContent: open ? "flex-start" : "center",
        // }}
      >
        {/*<b> LOGO</b>*/}
        <div className="text-center">
          <img style={{ height: open ?"85px":"40px", width: open ? "271.5px" : "auto", paddingLeft: open ? "0px" : "8px",marginLeft:open?'0':'20px',objectFit:open?'':'cover' }} src="/assets/mosqapp-logo-new.svg"/>
        </div>
      </div>
      <div
        // className="sidbaar-scroll"
        className={open ? "wScroll" : "bScroll"}
        // style={{
        //   width: open ? "20%" : "calc(20% - 10.9%)",
        // }}
      >
        {open ? (
          <>
            {SideBarLinks &&
        SideBarLinks.map((item, i) => (
          <div key={i} className="li-content li-margin">
            {item.name === "Add Member" ? (
              // If name is "Add Member", display dropdown
              <div style={styles.dropdownContainer}>
                <button
                  onClick={() => {active(i);setDropdownOpen(!dropdownOpen)}}
                  style={{...styles.dropdownButton,backgroundColor:'inherit' }}
                  
                >
                 <Icon
                  iconName={item.icon}
                  width="30px"
                  height="30px"
                  color={item.active ? "#fff" : "#D0004B"}
                />
                  <h5>{t(item.name)}</h5>
                  <span style={styles.arrow(dropdownOpen)}>⌃</span>
                </button>

                {dropdownOpen && (
                  <div style={styles.dropdownMenu}>
                    <NavLink
                      to={`/mosque/${activeEntity?._id}/${item.link}`}
                      style={({isActive})=>(isActive?{...styles.activeItem}:{...styles.dropdownItem})}
                    >
                      {t("Add Single Member")}
                    </NavLink>
                    <NavLink
                      to={`/mosque/${activeEntity?._id}/members/bulk-upload`}
                      style={({isActive})=>(isActive?{...styles.activeItem}:{...styles.dropdownItem})}
                    >
                      Bulk Upload
                    </NavLink>
                  </div>
                )}
              </div>
            ) : (
              // Default behavior for other links
              <NavLink
                to={`/mosque/${activeEntity?._id}/${item.link}`}
                onClick={() => active(i)}
                className={item.active ? "activeClass" : null}
              >
                <Icon
                  iconName={item.active ? item.whiteIcon : item.icon}
                  width="30px"
                  height="30px"
                  color={item.active ? "#fff" : "#D0004B"}
                />
                <h5>{t(item.name)}</h5>
              </NavLink>
            )}
          </div>
        ))}
              {/* <NavLink
              to={`/mosque/${activeEntity?._id}/donate`}
              onClick={()=>active(-1)}
              >
               <FundMeButton/>
              </NavLink> */}
            {/*<div className="li-content li-margin">*/}
            {/*  <NavLink*/}
            {/*      to={`/mosque/${activeEntity?._id}/members`}*/}
            {/*      onClick={() => handleBulkUpload()}*/}
            {/*      className={null}*/}
            {/*  >*/}
            {/*    <Icon*/}
            {/*        iconName={"WmessageQuestion"}*/}
            {/*        width="30px"*/}
            {/*        height="30px"*/}
            {/*        color={"#D0004B"}*/}
            {/*    ></Icon>*/}
            {/*    <h5>{"Add Bulk Members"}</h5>*/}
            {/*  </NavLink>*/}
            {/*</div>*/}
            {/* <NavLink
              to={`/mosque/${activeEntity?._id}/donate`}
              onClick={()=>active(-1)}
              >
               <FundMeButton isCollapsed={false}/>
              </NavLink> */}
          </>
        ) : (
          <>
            {SideBarLinks &&
              SideBarLinks.map((item, i) => (
                <div className="li-content li-content-black justify-content-center">
                  <NavLink
                    to={`/mosque/${activeEntity?._id}/${item.link}`}
                    onClick={() => active(i)}
                    className={item.active ? "activeClassWhite" : null}
                  >
                    <Icon
                      iconName={item.icon}
                      width="30px"
                      height="30px"
                      color={item.active ? "#D0004B" : "#D0004B"}
                    ></Icon>
                  </NavLink>
                </div>
              ))}
              {/* <NavLink
              to={`/mosque/${activeEntity?._id}/donate`}
              onClick={()=>active(-1)}
              >
               <FundMeButton isCollapsed={true}/>
              </NavLink> */}
          </>
          
        )}
        
        {/*{open ? (
          <div className="pink-card">
            <p style={{ color: "#fff" }}>
              Being devoted to learning, but subjected to the passing of time, in order to engage in work and endure great hardship.
            </p>
            <Button
              type="blank"
              iconName="donation"
              minWidth="180px"
              backgroundColor="#fff"
              textColor="#000"
              textAlign="center"
            >
              <Link
                to={`/mosque/${activeEntity?._id}/fund`}
                style={{ color: "#D0004B" }}
              >
                Donatiepagina
              </Link>
            </Button>
          </div>
        ) : (
          <div className="pink-card-white">
            <Link
              to={`/mosque/${activeEntity?._id}/fund`}
              style={{ color: "#D0004B" }}
            >
              <Icon iconName="donationWhite" m="auto" />
            </Link>
          </div>
        )}*/}
        {/*{open ? (
          <div
            className="li-content"
            style={{ padding: "10px 35px" }}
            onClick={() => setshow(true)}
          >
            <Link>
              <Icon
                iconName="wlogout"
                color={open ? " #D0004B" : "#fff"}
                width="22px"
                height="22px"
              ></Icon>
              <h4 style={{ color: " #D0004B" }}>Uitloggen</h4>
            </Link>
          </div>
        ) : (
          <Box>
            <Icon
              cursor="pointer"
              onClick={() => setshow(true)}
              iconName="wlogout"
              width="22px"
              color="#fff"
              m="auto"
              height="22px"
            ></Icon>
          </Box>
        )}*/}
      </div>
      <div

        // className="toggle-icon"
        className={open ? "wToggle" : "bToggle"}
        // style={{
        //   left: open ? "16%" : "5%",
        // }}

      >
        <Button
          icon
          onClick={handleClick}
          type="blank"
          iconName={open ? "whitetoggle" : "blacktoggle"}
        />
      </div>

      <Modal show={show} onHide={() => setshow(false)} className="logout-modal">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <button
              type="button"
              class="close"
              aria-label="Close"
              onClick={() => setshow(false)}
            >
              <span aria-hidden="true">
                <Icon iconName="modalClose" />
              </span>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <Icon iconName="Logout" style={{ margin: "auto" }} />
            <div className="text">{t('Are you sure you want to logout?')}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setshow(false)} className="cancel">
            {t('Cancel')}
          </button>
          <button onClick={() => handleLogOut()} className="logout">
            {t('Logout')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeftSidebar;
