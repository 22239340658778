import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "./components/Banner";
import Box from "../../../../components/Box";
import Label from "../../../../components/Label";
import BodyContainer from "./components/BodyContainerAgent";
import ResponseContainer from "../../../../components/SuccessPage/ResponseContainer";
import { useSelector, useDispatch } from "react-redux";
import { RESET_LINK_SUCCESS } from "../../adminActionType";
import { useTranslation } from "react-i18next";

const ForgetPage = () => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [isResetLinkSend, setIsResetLinkSend] = useState(false);
 const {t} = useTranslation()
  useEffect(() => {
    return () => {
    //   dispatch({
    //     type: RESET_LINK_SUCCESS,
    //     isFetching: false,
    //     isResetLinkSend: false,
    //   });
    };
  }, []);

console.log("reset value initial: ",isResetLinkSend);

  return (
    <div>
      {!isResetLinkSend ? (
        <>
        <Banner link={"/agent/login"} isResetLinkSend={isResetLinkSend} setIsResetLinkSend={setIsResetLinkSend}/>
        <BodyContainer
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          setIsResetLinkSend={setIsResetLinkSend}
        />
        </>
      ) : (
        <>
        <Banner link={"/agent/login"} isResetLinkSend={isResetLinkSend} setIsResetLinkSend={setIsResetLinkSend}/>
        <ResponseContainer>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Label
              color="#1A1A1A"
              variant="text2"
              fontWeight={700}
              lineHeight="24px"
            >
              {t('Password Reset Link Sent')}
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="16px"
            >
              {t('We have sent password reset link to')}
            </Label>
            <Label
              color="#222222c2"
              variant="text5"
              fontWeight={600}
              lineHeight="24px"
              mt="4px"
            >
              {userDetails?.email}
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="12px"
            >
              {t('Kindly open your email and click on the link')}
            </Label>
            <Label
              color="#7E7E7E"
              variant="text5"
              fontWeight={500}
              lineHeight="24px"
              mt="4px"
            >
              {t('to reset your password')}
            </Label>
          </Box>
        </ResponseContainer>
      </>
      )}
    </div>
  );
};

export default ForgetPage;
